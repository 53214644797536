import EmployeeService from '../core/services/EmployeeService';
import LocationService from '../core/services/LocationService';

/**
 * Load the employee and managed employees for a user's Profile. (Profile.js)
 * @returns {object} Employee and Managed Employees
 */
export async function loadEmployee() {
    const employee = await getEmployee();
    const managedEmps = await getManagedEmployees(employee.id);

    return {
        employee,
        managedEmps,
    };
};

/**
 * Load the employee, managed employees, and whether the user has a map record for their Profile view (ViewProfile.js).
 * @returns {object} Employee, Managed Employees, and if they have a Map record
 */
 export async function loadEmployeeView(employeeId) {
    const employee = await getEmployeeById(employeeId);
    const managedEmps = await getManagedEmployees(employeeId);
    const hasMap = await checkForMap(employeeId)

    return {
        employee,
        managedEmps,
        hasMap,
    };
};

// Get current employee
const getEmployee = async () => {
    const employee = await EmployeeService.getCurrentEmployee().catch(e => {
        throw new Error("Sorry, we couldn't load your account. Are you connected and logged in?");
    });

    return employee;
};

const getEmployeeById = async (employeeId) => {
    if (!(!isNaN(parseFloat(employeeId)) && isFinite(employeeId))) {
        throw { status: 404, title: "Employee not found" };
    }
 
    const employee = await EmployeeService.getEmployeeById(employeeId).catch(e => {
        throw e;
    });

    return employee;
};

const getManagedEmployees = async (employeeId) => {
    const managedEmps = await EmployeeService.getManagedByEmployees({ SupervisorEmployeeId: employeeId }).catch(e => {
        throw new Error("Failed to retrieve managed employees. Are you connected and logged in?");
    });

    return managedEmps;
}; 

// get the map with a single seating location based on the requested employeeId
const checkForMap = async (employeeId) => {
    const hasMap = await LocationService.getSeatingMapWithLocationByEmpId(employeeId).then(response => {
        return true;
    })
    .catch(e => {
        return false;
    });

    return hasMap;
};
