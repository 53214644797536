/* eslint-disable react-hooks/exhaustive-deps */
// Specifically ignoring these because it's complaining about method deps

import React, { useState, useEffect, useCallback } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconSearch from '@material-ui/icons/Search';
import IconArrowLeft from '@material-ui/icons/ArrowLeft';
import IconArrowRight from '@material-ui/icons/ArrowRight';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import EmployeeService from '../../core/services/EmployeeService';
import debounce from "lodash.debounce";

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { formatPhoneNumber, formatName } from '../../core/utils';
import { useQueryString } from '../../hooks/useQueryParams';
import { useError } from '../../hooks/useError';

import './style.scss';
import ProfilePhoto from '../../components/profilePhoto/ProfilePhoto';
import ErrorDisplay from '../../components/error/Error';

export default function Directory(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]); // all employees retrieved
  const [departments, setDepartments] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);

  // const [pageQuery, setPageQuery] = useState(1);
  // const [pageSizeQuery, setPageSizeQuery] = useState(25);

  // The values are persisted in the querystring to allow navigation back to the directory page
  const [nameQuery, setNameQuery] = useQueryString('name', '');  // text entered in the Employee Name search box
  const [departmentQuery, setDepartmentQuery] = useQueryString('department', '');
  const [pageQuery, setPageQuery] = useQueryString('page', '1');
  const [pageSizeQuery, setPageSizeQuery] = useQueryString('pageSize', process.env.REACT_APP_DIRECTORY_PAGE_SIZE);

  const [showLocations, setShowLocations] = useState(true); // flag to hide or show office location tiles
  
  const [errorObj, setError] = useError();


  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // Initial page load, load initial data
  useEffect(() => {
    setIsLoading(true);

    updateEmployeeGrid();
    getDepartments();
  },[]);
  
  const updateEmployeeGrid = () => {
    getEmployees();
    setShowLocations(nameQuery === '' && departmentQuery === '');
  };

  const delayedQuery = useCallback(debounce(updateEmployeeGrid, 500), [nameQuery]);

  // cancel the employee search filter whenever a new character is typed into the search field
  // Splitting the name apart from the department and office since it needs to be debounced
  useEffect(() => {
    delayedQuery();

    return delayedQuery.cancel;
  }, [nameQuery, delayedQuery]);

  useEffect(() => {
    updateEmployeeGrid();
  }, [departmentQuery, pageQuery]);


  const getEmployees = () => {
    const filters = {
      name: nameQuery,
      department: departmentQuery,
      page: pageQuery,
      pageSize: pageSizeQuery
    };

    EmployeeService.getAllEmployeesForGrid(filters)
      .then(response => {
        setEmployees(response.data);
        setTotalPages(response.pageCount);
        setTotalEmployees(response.total);
        setIsLoading(false);

      }).catch(e => {
          setError(true, e);
      });
  };

  const getDepartments = () => {
    EmployeeService.getDepartments()
      .then(response => {
        const departmentNames = response.map(x => x.name);
        
        setDepartments(departmentNames);
      })
      .catch(e => {
        console.error(e);
        setIsLoading(false);
      })
  };

  const handleNameQueryChange = e => {
    setNameQuery(e.target.value);
    setPageQuery(1);
  };

  const handleDepartmentQueryChange = e => {
    setDepartmentQuery(e.target.value);
    setPageQuery(1);
  };

  // Reset all user selections
  const clearSelection = () => {
    setNameQuery('');
    setDepartmentQuery('');
    setPageQuery(1);
    updateEmployeeGrid();
  };

  const pagePrevious = () => {
    if(pageQuery > 1)
    {
      setPageQuery(parseInt(pageQuery) - 1);
    }
  }

  const pageNext = () => {
    if(pageQuery < totalPages)
    {
      var nextPage = parseInt(pageQuery) + 1;
      setPageQuery(nextPage);
    }

  }

  const handleEmployeeRowClicked = (employee) => {
    history.push(`/employee/${employee.id}`);
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  const getTopPaginationButtons = () => {
    if (!isMobile) {
      return (                
        <div className='pagination-buttons-top'>
          <Button className="button-prev-page" variant="outlined" onClick={pagePrevious}><IconArrowLeft /> Prev</Button>
          {pageQuery}/{totalPages}
          <Button className="button-next-page" variant="outlined" onClick={pageNext}>Next <IconArrowRight /></Button>
        </div>
        )
    }
  }

  const renderDirectoryTableBody = () => {
    if (errorObj.error) {
      return <ErrorDisplay code={errorObj.code} message={errorObj.message} />
    }

    if (isLoading) {
      return <TableBody><TableRow><TableCell colSpan="7"><div>Loading...</div></TableCell></TableRow></TableBody>
    }

    if (!isLoading && employees.length < 1) {
      return <TableBody><TableRow><TableCell colSpan="7"><div>No StoryMakers found...</div></TableCell></TableRow></TableBody>
    }
    
    return (
      <TableBody>
        {
          employees.map((emp) => (
            <TableRow key={emp.id} onClick={() => handleEmployeeRowClicked(emp)} className="clickable">
              <TableCell>
                {/* If we are going to include images, we really need to paginate or limit the results that get returned. performance isn't great when many images are loaded and rendered */}
                <ProfilePhoto className="avatar-table" employee={emp} lazyLoad={true} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Link to={`/employee/${emp.id}`}><b>{formatName(emp)}</b></Link>
              </TableCell>
              <TableCell>{emp.officePhone ? formatPhoneNumber(emp.officePhone) : formatPhoneNumber(emp.mobilePhone)}</TableCell>
              <TableCell>{emp.title}</TableCell>
              <TableCell>{emp.emailAddress}</TableCell>
              <TableCell>{emp.department}</TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    );
  };

  return (
    <div>


      {/* Loading Mask */}
      <Backdrop className="backdrop" open={isLoading}>
        <CircularProgress />
      </Backdrop>

      { errorObj.error ?
          <ErrorDisplay code={errorObj.code} message={errorObj.message} />
      :
      <section className="card">
        <article className="search">
          <h1>StoryMaker Directory</h1>
          <p>Search for a StoryMaker or select a Hub to browse.</p>

          <FormControl className="searchFormControl search-input">
            <TextField
              onChange={handleNameQueryChange}
              value={nameQuery}
              id="userSearch"
              variant="outlined"
              label="StoryMaker Name"
              helperText=""
              disabled={isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch />
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          
          {departments.length > 0 ?
            <FormControl className="searchFormControl" variant="outlined">
              <InputLabel id="departmentLabel" style={{ whiteSpace: 'nowrap', padding: '0 5px' }}>Department</InputLabel>
              <Select
                key="selectDepartment"
                disabled={isLoading}
                labelId="departmentLabel"
                id="selectDepartment"
                style={{ minWidth: 180 }}
                value={departmentQuery}
                onChange={handleDepartmentQueryChange}
              >
                {departments && departments.map(dept => (
                  <MenuItem value={dept} key={dept}>{dept}</MenuItem>
                ))}
              </Select>
            </FormControl>
          : null }
        </article>
        <article className="directory-content">

          <div className="employees-label">
            <Button className="button-clear-location" variant="outlined" onClick={clearSelection}><IconArrowLeft /> Back</Button>
            {getTopPaginationButtons()}
          </div>

          <TableContainer>
            <Table size="small" className="user-table">
              <TableHead>
                <TableRow key="header">
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Primary Phone</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Department</TableCell>
                </TableRow>
              </TableHead>
              {renderDirectoryTableBody()}
            </Table>
          </TableContainer>
          <div className="pagination-buttons"> 
            <Button className="button-clear-location" variant="outlined" onClick={pagePrevious}><IconArrowLeft /> Prev</Button>
            Page {pageQuery} / {totalPages}
            <Button className="button-next-page" variant="outlined" onClick={pageNext}>Next <IconArrowRight /></Button>
          </div>
          <div className="employee-count-display">
            Showing {((pageQuery - 1) * pageSizeQuery) + 1}-{(pageQuery - 1) * pageSizeQuery + employees.length} of {totalEmployees}
          </div>

        </article>
      </section>

      }
    </div>
  );
}