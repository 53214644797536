// Based on https://material-ui.com/components/rating/#customized-ratings

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const ratingIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Value 1',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Value 2',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Value 3',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Value 4',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Value 5',
    },
};

function RatingIconContainer(props) {
    const { value, ...other } = props;

    if (!ratingIcons[value]) {
        return null;
    }

    return <span {...other}>{ratingIcons[value].icon}</span>;
}

export default function SmileyRatingResponse(props) {
    const { question, response, onChangeResponse, readOnly } = props;
    const [ responseValue, setResponseValue ] = useState(0);

    useEffect(() => {
        setResponseValue(parseInt(response ?? 0));
    }, [response]);

    const handleChange = (fieldvalue) => {
        if (readOnly) {
            return; // don't do anything if readonly
        }
        setResponseValue(parseInt(fieldvalue));

        if (onChangeResponse){
            // fieldvalue will be null when someone unselects a previously set value
            onChangeResponse(parseInt(question.id), fieldvalue?.toString());
        }
    };

    const getLabelText = (value) => {
        const icon = ratingIcons[value];
        if (icon) {
            return icon.label;
        }

        return null;
    }

    return (
        <Rating
          name={`question-${question.id}`}
          readOnly={readOnly}
          value={responseValue}          
          getLabelText={(value) => getLabelText(value)}
          IconContainerComponent={RatingIconContainer}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
        />
    )
};

SmileyRatingResponse.propTypes = {
    question: PropTypes.object.isRequired,
    response: PropTypes.string,
    onChangeResponse: PropTypes.func,
    readOnly: PropTypes.bool,
};