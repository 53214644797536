import React from "react"

export const timeZoneDifferential = (workplaceSurveyQuestions, workplaceSurveyResponses) => {
    try{
        const date = new Date();
        var offset = date.getTimezoneOffset() / -60;     
        const timeZoneQuestion = workplaceSurveyQuestions.find(q => q.manualInputLabel === "Please specify your geographic location/time zone")

        const timeZoneResponse = workplaceSurveyResponses[timeZoneQuestion.id];

        const timeZoneOFfsetsIgnoringDST = new Map([
            ["Eastern Daylight Time (New York)", -5],
            ["Central Daylight Time (Chicago)", -6],
            ["Mountain Daylight Time (Salt Lake City)", -7],
            ["Mountain Standard Time (Phoenix)", -7],
            ["Pacific Daylight Time (Los Angeles)", -8],
            ["Brasilia Standard Time (Sao Paulo)", -3],
            ["British Standard Time (London)", 1],
            ["Central European Time (Italy)", 1],
            ["Moscow Standard Time (Moscow)", 3],
            ["China Standard Time (Beijing)", 8],
            ["Korean Standard Time (Seoul)", 9],
            ["Japan Standard Time (Tokyo)", 9],
            ["Australian Eastern Standard Time (Sydney)", 10],
            ["New Zealand Standard Time (Auckland)", 12],
            ["Other"]
        ]);

        var profileOffset = timeZoneOFfsetsIgnoringDST.get(timeZoneResponse.responseValue);

        console.log(profileOffset)
        console.log(offset)

        if(hasDST())
        {
            offset -= 1;
        }

        const timezoneDiff = (profileOffset - offset)



        if(timezoneDiff == 0)
            return (<b>You are in the same time zone.</b>)
        if(timezoneDiff > 0)
            return (<b>You are {timezoneDiff} hour{timezoneDiff == 1 ? '' : 's'} behind.</b>)

        return (<b>You are {Math.abs(timezoneDiff)} hour{Math.abs(timezoneDiff) == 1 ? '' : 's'} ahead.</b>)
    } catch (error) {
        console.log(error)
        return "";
    }
}

function hasDST(date = new Date()) {
    const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(january, july) !== date.getTimezoneOffset();
  }