/* eslint-disable react-hooks/exhaustive-deps */
// Specifically ignoring these because it's complaining about method deps

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { resolveProfileImage, formatName } from '../../core/utils';

export default function ProfilePhoto({
    employee,
    className,
    lazyLoad,
    forceRefresh
}) {
    const classNames = () => {
        let classes = 'avatar';
        if (className) {
            classes = `${classes} ${className}`;
        }
        return classes;
    }

    const defaultPhotoUrl = `${process.env.PUBLIC_URL}/assets/img/placeholder-avatar.png`;

    const [ imageUrl, setImageUrl ] = useState(defaultPhotoUrl);
    const [ photoAltDesc, setPhotoAltDesc ] = useState(null);
    
    useEffect(() => {
        if (employee && employee.imageFileName) {
            setImageUrl(resolveProfileImage(employee, forceRefresh));
            
            // TODO: Use preferred name (make a method to centralize all of these too)
            setPhotoAltDesc(`${formatName(employee)} photo`);
        } else {
            setImageUrl(defaultPhotoUrl);
            setPhotoAltDesc('Profile photo placeholder');
        }
    }, [ employee ])

    return (
        <img 
            className={classNames()} 
            src={imageUrl} 
            alt={photoAltDesc}
            loading={lazyLoad ? "lazy" : null }
            onError={(e) => { 
                e.target.onError = null; 
                e.target.src = defaultPhotoUrl
            }} />
    )
}

ProfilePhoto.propTypes = {
    employee: PropTypes.object,
    className: PropTypes.string,
    lazyLoad: PropTypes.bool,
    forceRefresh: PropTypes.bool
};