/* eslint-disable react-hooks/exhaustive-deps */
// Specifically ignoring these because it's complaining about method deps

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

// this imports the minimal core version of react-konva and we need to manually import any shapes we intend to use
// https://www.npmjs.com/package/react-konva
import { Stage, Layer, Rect, Image, Circle, Text, Group } from 'react-konva/lib/ReactKonvaCore';
import "konva/lib/shapes/Text";
import "konva/lib/shapes/Circle";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Image";

import useImage from 'use-image'; // hook for generating images and easily using them in the konva layers
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconMeetingRoom from '@material-ui/icons/MeetingRoom';
import IconAccountCircle from '@material-ui/icons/AccountCircle';

import LocationService from '../../core/services/LocationService';

import { useSnackbar } from 'notistack';

import './style.scss';
import { Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

// if we have an employeeId, we only display that single employee
// that employeeId is used to hide LOTS of the more general map elements in this component

export default function SeatingMap(props) {
    // for refs to our konva (canvas) items
    const stageRef = useRef(null);
    const tooltipRef = useRef(null);
    const tooltipLayerRef = useRef(null);
    const tooltipContainerRef = useRef(null);

    const [map, setMap] = useState({}); // this will hold the seating map object
    const [mapScale, setMapScale] = useState(1); // dynamic scaling of the map
    const [stageContainerWidth, setStageContainerWidth] = useState(0);
    const { id, employeeId, officeName } = props; // id is the map Id, employeeId is the particular employee to highlight (it's optional)
    const [isLoading, setIsLoading] = useState(true);
    const [showEmployeePins, setShowEmployeePins] = useState(true); // set this to show all employees by default
    const [showRoomPins, setShowRoomPins] = useState(true); // set this to show all conference rooms by default
    const [selectedRoom, setSelectedRoom] = useState(''); // this is the specific conference room selected
    const [mapImage] = useImage(map && map.imageUrl, 'Anonymous'); // second param to useImage is cross-origin
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    // Stage render detection, used for map scaling. This lets us scale
    // the map relative to its container rather than the window. Necessary
    // to fit within the Employee Detail modal.
    const stageContainerRef = useRef();
    const [stageContainerVisible, setStageContainerVisible] = useState(false);
    useEffect(() => {
      if (!stageContainerVisible) { 
        // nothing rendered yet, bail out
        return
      }
      // render detected, do our thing
      const determineMapContainerDimensions = () => {
        setStageContainerWidth(stageContainerRef.current.offsetWidth); 
      };
      determineMapContainerDimensions();      
      // update stageContainerWidth var when the window is resized
      window.addEventListener("resize", determineMapContainerDimensions);
      return () => window.removeEventListener("resize", determineMapContainerDimensions);
    }, [stageContainerVisible]);

    // Scale the map based on its container width.
    useEffect(() => {
      if (map === {} || map === null) return; // don't do anything else here if the map object is empty         
      
      const determineMapScale = () => {
        let boundedWidth = stageContainerWidth;
        let scale = ((boundedWidth / map.width) * .85);
        let scaleCorrected = 0;
        // prevent the map from displaying larger than 100% of the map image size
        if (scale > 1) {
          scaleCorrected = 1;
        } else {
          scaleCorrected = scale;
        }
        setMapScale(scaleCorrected);
      }
      determineMapScale();

    // we don't need a listener here for the resize event because we have stageContainerWidth as a dependency
    // and the effect that is setting that is already listening for resize events

    }, [stageContainerWidth, map]);

    // get the map with all the seating locations
    const getMap = (id) => {
        LocationService.getMapWithLocations(id)
            .then(response => {
                setMap(response);    
                setIsLoading(false);
            })
            .catch(e => {
                enqueueSnackbar(`Failed to retrieve Seating Map. Are you connected and logged in?`, { 'variant': 'error' });
                setIsLoading(false);
                console.error(e);
            });
    };

    // get the map with a single seating location based on the requested employeeId
    const getMapByEmployeeId = (employeeId) => {
        LocationService.getSeatingMapWithLocationByEmpId(employeeId)
            .then(response => {
                setMap(response);
                setIsLoading(false);
            })
            .catch(e => {
                // we don't want to display a snackbar whenever an employee isn't mapped yet. it's not really an error per se.
                //enqueueSnackbar(`Failed to retrieve Seating Map. It doesn't appear that this employee's location is mapped`, { 'variant': 'warning' });
                setMap(null);
                setIsLoading(false);
                console.error(e);
            });
    };

    // on load get our map, and if we have an employeeId get that person and their specific map
    useEffect(() => {
        setIsLoading(true);
        // if we have an employee Id, just load their location
        if (employeeId) {
            getMapByEmployeeId(employeeId);
        }
        else {
            // otherwise get the map with all locations
            getMap(id);
            setSelectedRoom(''); // when we change between floors, we want to remove the selected conference room
        }

    }, [id, employeeId]);

    // both these "handleLocation" event handlers use refs to the konva elements
    // they show the tooltip elements when someone hovers over the locations
    const handleLocationMouseOver = (tooltipText) => {
        // calculate the tooltip location based on where we are in the image (and offset it so it doesn't overflow)
        var mousePos = stageRef.current.getPointerPosition();
        var stageWidth = stageRef.current.attrs.width;
        var stageHeight = stageRef.current.attrs.height;
        // if we are halfway up or across the stage (image) then flip the tooltip to the other side of the mouse
        let offsetX = mousePos.x < (stageWidth / 2) ? (mousePos.x + 5 * mapScale) : (mousePos.x - 200 * mapScale);
        let offsetY = mousePos.y < (stageHeight / 2) ? (mousePos.y + 5 * mapScale) : (mousePos.y - 20 * mapScale);
        let tooltip = tooltipRef.current;
        tooltip.position({
            x: offsetX / mapScale,
            y: offsetY / mapScale,
        });

        if (tooltipText === 'No EmployeeId') {
          tooltip.text('No Data Available');
        } 
        else {
          tooltip.text(tooltipText); 
        }        
        
        tooltip.show();

        let tooltipContainer = tooltipContainerRef.current;
        tooltipContainer.position({
            x: offsetX / mapScale,
            y: offsetY / mapScale,
        });
        tooltipContainer.show();
        tooltipLayerRef.current.batchDraw();
    };

    // just hide the tooltip when someone moves off of the point
    const handleLocationMouseOut = () => {
        tooltipRef.current.hide();
        tooltipContainerRef.current.hide();
        tooltipLayerRef.current.draw();
    };
    const toggleEmployeePins = () => {
        setShowEmployeePins(!showEmployeePins);
    };

    const toggleRoomPins = () => {
        setShowRoomPins(!showRoomPins);
    };

    const handleRoomSelect = e => {
        setSelectedRoom(e.target.value);
    };

    // display a pointer when a pin is clickable
    const handleEmpPinMouseEnter = (empId) => {
        // don't display the pointer on the individual employee map
        if (!Number.isInteger(parseInt(empId)) || employeeId) { return; }
        stageRef.current.container().style.cursor = 'pointer';
    };

    // go back to the default cursor
    const handleEmpPinMouseLeave = (empId) => {
        if (!Number.isInteger(parseInt(empId)) || employeeId) { return; }
        stageRef.current.container().style.cursor = 'default';
    };

    const handleEmployeeClicked = (empId) => {
        // If we don't have an empId for the seating map location OR we are on the single employee view, bail
        if (!Number.isInteger(parseInt(empId)) || employeeId) { 
            return; 
        }

        history.push(`/employee/${empId}`);
    };

    return (
        isLoading ? <Backdrop className="backdrop" open={isLoading}><CircularProgress /></Backdrop> :
            map ?
                <div>
                    <span>{employeeId ? officeName + ' - ' + map.displayName : ''} </span>
                    <div className="seating-map-select-wrapper">
                        {employeeId ? null :
                            <div>
                                {!map.seatingMapLocations.some(x => { return x.isConferenceRoom }) ? null
                                    :
                                    <span>
                                        <FormControl className="searchFormControl" variant="outlined">
                                            <InputLabel id="roomLabel" style={{ whiteSpace: 'nowrap' }}>Conference Room</InputLabel>
                                            <Select
                                                key="selectRoom"
                                                disabled={employeeId}
                                                labelId="roomLabel"
                                                id="selectRoom"
                                                style={{ minWidth: 180 }}
                                                value={selectedRoom}
                                                onChange={handleRoomSelect}
                                            >
                                                {map.seatingMapLocations.filter(x => { return x.isConferenceRoom }).sort((a, b) => { return a.displayName.localeCompare(b.displayName) }).map(location => <MenuItem key={'room' + location.id} value={location.id}>{location.displayName}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <Button onClick={toggleRoomPins} variant="outlined" className="button-toggle" style={{ marginRight: '1em' }}><IconMeetingRoom className="icon" style={{ fill: '#E63D2F' }} /> Toggle Conference Rooms</Button>
                                    </span>
                                }
                                <Button onClick={toggleEmployeePins} variant="outlined" className="button-toggle"><IconAccountCircle className="icon" style={{ fill: '#4A90E2' }} /> Toggle StoryMakers</Button>
                            </div>
                        }
                    </div>
                    {!map ? null :
                        <div id="stageContainer" ref={el => { stageContainerRef.current = el; setStageContainerVisible(!!el); }}>
                            <Stage width={stageContainerWidth} height={map.height * mapScale} scaleX={mapScale} scaleY={mapScale} id="mapStage" ref={stageRef}>
                                <Layer id="backgroundLayer">
                                    <Image image={mapImage} />
                                </Layer>
                                <Layer id="locationsLayer">
                                    {/* draw the employee locations */}
                                    <Group id="EmployeePins" key="EmployeePins">
                                        {map && map.seatingMapLocations && map.seatingMapLocations
                                            .filter(x => { return !x.isConferenceRoom && x.employeeId !== null })
                                            .map(location => (
                                                <Circle
                                                    radius={10}
                                                    id={location.id}
                                                    key={location.id}
                                                    fill={"#4A90E2"}
                                                    stroke={"white"}
                                                    x={location.x}
                                                    y={location.y}
                                                    scaleX={employeeId ? 1.3 : .6}
                                                    scaleY={employeeId ? 1.3 : .6}
                                                    opacity={1}
                                                    onMouseEnter = {() => handleEmpPinMouseEnter(`${location.employeeId}`)}
                                                    onMouseLeave = {() => handleEmpPinMouseLeave(`${location.employeeId}`)}
                                                    onMouseOver={() => handleLocationMouseOver(`${location.description}`)}
                                                    onMouseOut={handleLocationMouseOut}
                                                    onClick={() => handleEmployeeClicked(location.employeeId)}
                                                    // TODO: decide if we want to display the tooltip onTouchStart and then move the modal to a touch of the tooltip
                                                    onTouchEnd={() => handleEmployeeClicked(location.employeeId)}
                                                    visible={employeeId || (showEmployeePins && location.employeeId !== "") ? true : false}
                                                />
                                            )
                                            )}
                                    </Group>
                                    {/* draw the conference rooms */}
                                    <Group id="ConferenceRoomPins" key="RoomPins"  >
                                        {map && map.seatingMapLocations && map.seatingMapLocations
                                            .filter(x => { return x.isConferenceRoom })
                                            .map(location => (
                                                <Rect
                                                    width={20} // needed for Rect
                                                    height={20} // needed for Rect
                                                    rotation={45} // makes our rectangles look like diamonds
                                                    visible={showRoomPins}
                                                    id={location.id}
                                                    key={location.id}
                                                    fill={"#E63D2F"}
                                                    stroke={"white"}
                                                    x={location.x}
                                                    y={location.y}
                                                    scaleX={.6}
                                                    scaleY={.6}
                                                    offsetX={6}
                                                    offsetY={6}
                                                    opacity={1}
                                                    onMouseOver={() => handleLocationMouseOver(`${location.displayName}`)}
                                                    onMouseOut={handleLocationMouseOut}
                                                />
                                            )
                                            )}
                                    </Group>
                                    {/* draw the highlighted conference rooms (last to render so they'll be on top) */}
                                    <Group id="SelectedConferenceRoomPins" key="SelectedRoomPins"  >
                                        {map && map.seatingMapLocations && map.seatingMapLocations
                                            .filter(x => { return x.isConferenceRoom })
                                            .map(location => (
                                                <Rect
                                                    width={30} // needed for Rect
                                                    height={30} // needed for Rect
                                                    rotation={45} // makes our rectangles look like diamonds
                                                    visible={selectedRoom === location.id}
                                                    id={`selected${location.id}`}
                                                    key={`selected${location.id}`}
                                                    fill={"#7ED321"}
                                                    stroke={"white"}
                                                    x={location.x}
                                                    y={location.y}
                                                    scaleX={.6}
                                                    scaleY={.6}
                                                    offsetX={6}
                                                    offsetY={6}
                                                    opacity={1}
                                                    onMouseOver={() => handleLocationMouseOver(`${location.displayName}`)}
                                                    onMouseOut={handleLocationMouseOut}
                                                />
                                            )
                                            )}
                                    </Group>
                                </Layer>
                                <Layer id="tooltipLayer" ref={tooltipLayerRef}>
                                    <Rect
                                        fill="#222"
                                        width={200}
                                        height={24}
                                        shadowColor="black"
                                        shadowBlur={10}
                                        shadowOffsetX={10}
                                        shadowOffsetY={10}
                                        shadowOpacity={0.2}
                                        cornerRadius={5}
                                        visible={false}
                                        ref={tooltipContainerRef}
                                        scaleX={employeeId ? 1.8 : 1.1}
                                        scaleY={employeeId ? 1.8 : 1.1}
                                    />
                                    <Text
                                        text=""
                                        fontFamily="roboto"
                                        fontSize={16}
                                        padding={5}
                                        textFill="white"
                                        textAlign="center"
                                        fill="white"
                                        alpha={1}
                                        visible={false}
                                        ref={tooltipRef}
                                        scaleX={employeeId ? 1.8 : 1.1}
                                        scaleY={employeeId ? 1.8 : 1.1}
                                    />
                                </Layer>
                            </Stage>
                        </div>
                    }
                </div>
                : <p className="noMap"><em>Sorry, there is no desk location data for this employee.</em></p>
    );

}
