import {Api} from '../api'

// returns pretty much all the emp fields
const getAllOffices = () => {
  return Api.get("/Location/offices");
};

const getOfficesWithMaps = () => {
  return Api.get("/Location/officesWithMaps");
};

const getOfficeWithMaps = (id) => {
  return Api.get(`/Location/officesWithMaps/${id}`);
};

const getMapWithLocations = (id) => {
  return Api.get(`/Location/seatingmapswithlocations/${id}`);
};

const getSeatingMapWithLocationByEmpId = (employeeId) => {
  return Api.get(`/Location/employeeseatingmapwithlocation/${employeeId}`);
};

export default {
  getAllOffices,
  getOfficesWithMaps,
  getOfficeWithMaps,
  getMapWithLocations,
  getSeatingMapWithLocationByEmpId
};