export const AgencyTabIds = {
    gmr: 0,
    omceg: 1,
    storyHouse: 2
};

export const AgencyTabNames = {
    gmr: "GMR Marketing",
    omceg: "OMCEG",
    storyHouse: "StoryHouse"
};