import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import IconArrowLeft from '@material-ui/icons/ArrowLeft';
import IconWarning from '@material-ui/icons/Warning';

import config from '../../config';
import ReportService from '../../core/services/ReportService';
import EmployeeService from '../../core/services/EmployeeService';
import ErrorDisplay from '../../components/error/Error';

import { useError } from '../../hooks/useError';

import './style.scss';

export default function SurveyDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [trustedTicket, setTrustedTicket] = useState(null);
  const [errorObj, setError] = useError();
  const [tbError, setTbError] = useState(false);

  function renderTableau(ticket) {
    return (
      <div className='tableauPlaceholder' style={{ width: '100%', height: '827px' }}>
        <object className='tableauViz' width='100%' height='827' style={{ display: 'none' }} key={ticket}>
          <param name='host_url' value={config.tableau.url} />
          <param name='embed_code_version' value='3' />
          <param name='site_root' value={config.tableau.siteRoot} />
          <param name='ticket' value={ticket} />
          <param name='name' value='Vantage/VantageTeams' />
          <param name='tabs' value='no' />
          <param name='toolbar' value='yes' />
          <param name='showAppBanner' value='false' />
        </object>
      </div>
    );
  }

  // Verify the user has access to view the dasbhoard
  // TODO: Figure out how to get this to play nicely with
  // React Router and not throw memory leak warnings
  useEffect(() => {
    EmployeeService.getCurrentEmployee().then(employee => {
      if (!employee || !employee.hasAccessToTableauDashboardPage) {
        document.location = '/survey';
      }
    }).catch(e => {
        setError(true, e, "Sorry, we couldn't load your account. Are you connected and logged in?");
    });
  }, [ ]);

  useEffect(() => {
    // Append the Tableau script the page
    const script = document.createElement('script');
    script.src = `${config.tableau.url}javascripts/api/viz_v1.js`;
    script.type = 'text/javascript';
    document.body.appendChild(script);

    return() => {
      document.body.removeChild(script);
    }
  }, [ ]);

  useEffect(() => {
    setIsLoading(true);

    ReportService.getTableauTicket().then(response => {
      // Tableau will return a "-1" response if auth fails
      if (response.ticket === '-1') {
        setError(true, { status: 0, title: 'Tableau authorization failed.' });
        setTbError(true);
      } else {      
        setTrustedTicket(response.ticket);
      }

      setIsLoading(false);
    });
  },[]);

  const handleBackButton = () => document.location = '/survey';

  return (
    <div>
      
      { errorObj.error && !tbError ?
          <ErrorDisplay code={errorObj.code} message={errorObj.message} detail={errorObj.detail} />
      :
      <section className="card narrow survey-dashboard">
        <article>
          <h1>Survey: Dashboard</h1>
          <Button variant="outlined" className="button-back" color="primary" onClick={handleBackButton}><IconArrowLeft /> Return to Survey</Button>
          <p><strong><IconWarning style={{float: 'left', marginRight: '5px', fontSize: '18px', color: 'orange'}} /> Please note:</strong><br />
            If you are prompted to login below, <strong>please do not attempt to login</strong>. If you are seeing a login form your browser does not have third-party cookies enabled, and attempting to login may lock you out of your account.</p>
          <p>Instead, please see <a href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/auto" target="_blank" rel="noopener noreferrer">instructions here on enabling third-party cookies</a>. Once you have updated your browser settings, reload this page and you should no longer see the login prompt.</p>
        </article>        
      </section>
      }
      
      { isLoading && !errorObj.error ? <p>Loading...</p> : null }

      {!errorObj.error && !tbError ? renderTableau(trustedTicket) : <ErrorDisplay code={errorObj.code} message={errorObj.message} detail={errorObj.detail} />}
      
    </div>
  );
}
