import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconAccountCircle from '@material-ui/icons/AccountCircle';
import IconLock from '@material-ui/icons/Lock';
import IconVpnKey from '@material-ui/icons/VpnKey';

function LoginForm({
    onSubmitLogin,
}) {
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            handleLogin();
        }
    };

    const handleLogin = (e) => {
        onSubmitLogin(username, password);
    }

    return (
        <>
            <TextField
                id="txt-login"
                name="login"
                label="Email or Username"
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus={true}
                value={username}
                onKeyPress={handleKeyPress}
                onChange={handleUsernameChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <IconAccountCircle />
                    </InputAdornment>
                    ),
                }}
            />

            <TextField
                id="txt-password"
                name="password"
                label="Password"
                type="password"
                margin="normal"
                fullWidth
                variant="outlined"
                value={password}
                onKeyPress={handleKeyPress}
                onChange={handlePasswordChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <IconLock />
                    </InputAdornment>

                    ),
                }}
            />

            <div className="login-button-container">
                <Button
                    variant="contained"
                    disableElevation
                    disableRipple
                    size="medium"
                    color="primary"
                    startIcon={<IconVpnKey />}
                    onClick={handleLogin}
                >
                    Login
                </Button>
            </div>
        </>
    )
}

LoginForm.propTypes = {
    onSubmitLogin: PropTypes.func.isRequired,
};

export default LoginForm;