/* eslint-disable react-hooks/exhaustive-deps */
// Specifically ignoring these because it's complaining about method deps

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import IconBarChart from '@material-ui/icons/BarChart';
import IconCheck from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';

import EmployeeService from '../../core/services/EmployeeService';
import QuestionnaireService from '../../core/services/QuestionnaireService';
import ProfileQuestionService from '../../core/services/ProfileQuestionService';
import IntroQuestionGroups from '../../core/IntroQuestionGroups';
import ErrorDisplay from '../../components/error/Error';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useError } from '../../hooks/useError';

import './style.scss';

export default function Survey() {
  const history = useHistory();

  const [employee, setEmployee] = useState({});
  const [categories, setCategories] = useState([]);
  const [categoriesRespondedTo, setCategoriesRespondedTo] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [errorObj, setError] = useError();

  useEffect(() => {
    EmployeeService.getCurrentEmployee().then(emp => {
      setEmployee(emp);
    }).catch(e => {
      setError(true, e, "Sorry, we couldn't load your account. Are you connected and logged in?");
  });
  }, []);

  // on load get our categories (and their nested topics)
  useEffect(() => {
    getCategories();
    getCategoriesRespondedTo(1); // TODO: replace this parameter (hardcoded to 1) with the current user Id when we start retrieving it
  }, []);

  // on load get our questions (repeated for each topic)
  useEffect(() => {
    getQuestions();
  }, []);

  // This has to come last otherwise the page complains about aborted requests
  // TODO: Determine if we can combine with the other hooks/API calls 
  //
  // Make sure the user has their intro questions completed. If not, redirect to complete them
  useEffect(() => {
    ProfileQuestionService.verifyQuestionsCompleted(IntroQuestionGroups.VantageSurveyIntro).then(response => {
      if (!response && errorObj.error) {
        // The returnUrl param is not neded at this time, but we can uncomment if it's needed in the future.
        //history.push(`/introquestions/questions/${IntroQuestionGroups.VantageSurveyIntro}?returnUrl=/survey`);
        history.push(`/introquestions/questions/${IntroQuestionGroups.VantageSurveyIntro}`);
      }
    });
  }, []);

  // get all categories (With nested topics)
  const getCategories = () => {
    QuestionnaireService.getAllCategories()
      .then(response => {
        setCategories(response);
      })
      .catch(e => {
        enqueueSnackbar(`Failed to retrieve Survey categories. Are you connected and logged in?`, { 'variant': 'error' });
        console.error(e);
      });
  };

  // get an array of just the category Ids for which this user has answered at least one question
  const getCategoriesRespondedTo = (employeeId) => {
    QuestionnaireService.getCategoriesRespondedTo(employeeId)
      .then(response => {
        setCategoriesRespondedTo(response);
      })
      .catch(e => {
        console.error(e);
      });
  };

  // get our questions and store them on the window object (so they're available to the topics that each category will render)
  // this could get removed and just happen on the topic component. maybe we should do that?
  const getQuestions = () => {
    QuestionnaireService.getAllQuestions()
      .then(response => {
        // tack the question set onto the window so we can get it there wherever we need it
        window.vantageQuestions = response;
      })
      .catch(e => {
        enqueueSnackbar(`Failed to retrieve Survey questions. Are you connected and logged in?`, { 'variant': 'error' });
        console.error(e);
      });
  };

  const handleIntroButton = () => {
    history.push(`/introquestions/questions/${IntroQuestionGroups.VantageSurveyIntro}`);
  };

  return (
    <div>

      { errorObj.error ?
          <ErrorDisplay code={errorObj.code} message={errorObj.code} detail={errorObj.detail} />
      :
      <section className="card narrow border-bottom">

        <article className="questionsHeader">
          <h1>Survey: Categories</h1>

          {
            employee.hasAccessToTableauDashboardPage
              ? <div className="dashboard-link">
                <a href="/surveydashboard"><IconBarChart className="icon-survey-results" /> View Survey Results</a>
              </div>
              : null
          }

          {categories && categories.length > 0 ?
            (
              <div>
                <p>The goal of this survey is to update our expert pool across a broad range of topics. We are specifically looking at two different levels of expertise:</p>
                <ul>
                  <li><strong>Professional</strong> &ndash; a comprehensive knowledge and/or skill in the topic based on your prior work experience (GMR or elsewhere)</li>
                  <li><strong>Personal</strong> &ndash; a comprehensive knowledge and/or skill in a topic based on your personal passion</li>
                </ul>
                <p>Everyone who takes this survey could be called upon for their expertise to better serve agency clients. For example, some experts have participated in creative brainstorms, organized focus groups or written white papers on a topic.</p>
                <hr className="separator" />
                <p>For each of the categories below, select those in which you have expertise and share what level of expertise you have with any of the relevant topics found in that category. You can answer as many or as few categories/topics as you like. Green check marks next to categories confirm you provided and saved results within that category.</p>
                <p>You can return at any time to update your results.</p>

              </div>
            ) : null
          }

        </article>

        {categories && categories.length > 0 ?
          (
            <article style={{ paddingTop: 0 }}>
              <div className="intro-button-container">
                <Button variant="outlined" className="intro-button" color="primary" onClick={handleIntroButton}>
                  Introduction Questions
                  <IconCheck className="intro-button-icon" />
                </Button>
              </div>
              <ul className="category-list">
                {categories && categories.map((category, index) => (
                  <li key={category.id} >
                    <Link to={`/survey/category/${category.id}`}>{category.name}</Link> {(categoriesRespondedTo.indexOf(category.id) >= 0) ? <IconCheck className="category-complete-icon" titleAccess="You have responded to questions in this category" /> : null}
                  </li>
                ))}
              </ul>
            </article>
          )
          : <article><div>Loading...</div></article>}
      </section>
      }

    </div>
  );
}
