import { Api } from '../api'

// returns pretty much all the emp fields
const getAllEmployees = () => {
  return Api.get("/Employee");
};

// returns a lightweight DTO
const getAllEmployeesForGrid = (query) => {
  let qs = '';

  // split the object into a query string
  if (query) {
    qs = Object.keys(query)
      .filter(x => query[x])
      .map(key => `${key}=${encodeURIComponent(query[key])}`)
      .join('&');
  }
  return Api.getPage(`/Employee/grid?${qs}`);
};

const getManagedByEmployees = (query) => {
  let qs = '';

  // split the object into a query string
  if (query) {
    qs = Object.keys(query)
      .map(key => `${key}=${query[key]}`)
      .join('&');
  }

  return Api.get(`/Employee/grid?${qs}`);
};

const getEmployeeById = (id) => {
  return Api.get(`/Employee/${id}`);
};

const getCurrentEmployee = () => {
  return Api.get('/Employee/current');
};

// VAN-124: We were running into a race condition in the Profile view when trying to use the getManagedByEmployees function
// since we had to call getCurrentEmployee first, wait for the results, and then use the ID for the call to getManagedByEmployees.
// This new function just uses the current user's details on the back end
const getReportsForCurrentUser = () => {
  return Api.get('/Employee/reportsForCurrentUser');
};

const generateAweResponsesSpreadsheet = () => {
  return Api.get('/ProfileResponse/profileResponsesForReports');
};

const getEmployee = (query) => {
  let qs = '';

  // split the object into a query string
  if (query) {
    qs = Object.keys(query)
      .map(key => `${key}=${query[key]}`)
      .join('&');
  }
  return Api.get(`/Employee?${qs}`);
};

const getDepartments = () => {
  return Api.get('/Employee/departments');
};

const saveProfile = (profile) => {
  return Api.put("/Employee/profile", profile);
};

const saveProfilePhoto = (image) => {
  const headers = {
    'Content-type': null
  };

  let data = new FormData();
  data.append('imageData', image);

  return Api.post('/Employee/photo', data, headers);
}


export default {
  getAllEmployees,
  getAllEmployeesForGrid,
  getManagedByEmployees,
  getEmployee,
  getEmployeeById,
  getCurrentEmployee,
  getReportsForCurrentUser,
  generateAweResponsesSpreadsheet,
  getDepartments,
  saveProfile,
  saveProfilePhoto
};