export default {
    VantageSurveyIntro: 1,
    AnyplaceWorkplaceExperience: 2,
};

export const ProfileQuestionSubGroupIds = {
    communicationPreferences: 3,
    communicationPreferenceRatings: 9
};

export const CommunicationPreferenceRatingQuestionIds = [29, 30, 31, 32, 33, 34, 36]