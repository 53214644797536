import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';

function PronounSelector(props) {
    const { pronouns, onPronounsChanged } = props;
    
    const [selectedValue, setSelectedValue] = useState('');
    const [customValue, setCustomValue] = useState('');
    const [valueToSave, setValueToSave] = useState('');

    // pick up a ref to our custom pronoun input (for focusing on it)
    const customPronounInput = useRef(null);

    const standardPronounOptions = [
        "She/Her/Hers",
        "He/Him/His",
        "They/Them/Theirs",
    ];

    useEffect(() => {
        // Check if the pronouns passed in are in the "standard" list
        if (pronouns && !standardPronounOptions.includes(pronouns)) {
            setSelectedValue('Other - Specify'); // set what we'll show in the <select>
            setCustomValue(pronouns); // populate the custom field
        } else if (pronouns) { // if we have a value...
            setSelectedValue(pronouns);
            // customValue will be empty by default
        }

        setValueToSave(pronouns);
    }, []);

    // Pass the value back up to the parent
    useEffect(() => {
        onPronounsChanged(valueToSave);
    }, [valueToSave]);
    
    // when a user selects the Other option, focus on the "Specify Pronouns" text input
    // we can't set it before the render because the select will take the focus back after the selection
    // and we can't focus on load because the input starts out as hidden (so we check to make sure the ref to the DOM element exists before focusing)
    useEffect(() => {
        if (selectedValue === 'Other - Specify' && customPronounInput.current) {
            customPronounInput.current.focus();
        }
    }, [selectedValue]);

    const handlePronounDropdownChange = (e) => {
        let pronounValue = e.target.value;
        setSelectedValue(pronounValue);

        if (pronounValue !== 'Other - Specify') {
            setValueToSave(pronounValue);
            setCustomValue('');
        }
    };

    // the custom pronouns handler...
    const handleCustomPronounChange = (e) => {
        setCustomValue(e.target.value);
        setValueToSave(e.target.value);
    };

    return (
        <div className="pronoun-selection-container">
            <FormControl className="pronounFormControl" variant="outlined">
                <InputLabel id="pronounLabel" style={{ whiteSpace: 'nowrap', padding: '0 5px' }}>Pronouns</InputLabel>
                <Select
                    labelId="pronounLabel"
                    label="Pronouns"
                    id="selectPronouns"
                    className="pronouns-select"
                    value={selectedValue ? selectedValue : ""}
                    onChange={handlePronounDropdownChange}
                >
                    <MenuItem value="">Do Not Display</MenuItem>
                    <MenuItem value="She/Her/Hers">She/Her/Hers</MenuItem>
                    <MenuItem value="He/Him/His">He/Him/His</MenuItem>
                    <MenuItem value="They/Them/Theirs">They/Them/Theirs</MenuItem>
                    <MenuItem value="Other - Specify">Other - Please Specify</MenuItem>
                </Select>
                <div className={selectedValue === "Other - Specify" ? "pronoun-custom-container show" : "pronoun-custom-container hide"}>
                <TextField
                    id="customPronouns"
                    className="pronouns-custom"
                    variant="outlined"
                    label="Specify Pronouns"
                    value={customValue ? customValue : ''}
                    onChange={handleCustomPronounChange}
                    inputRef={customPronounInput}
                />
                </div>
            </FormControl>
        </div>
    )
}

PronounSelector.propTypes = {
    pronouns: PropTypes.string.isRequired,
    onPronounsChanged: PropTypes.func,
};

export default PronounSelector;