import { Api } from '../api';

const getTableauTicket = () => {
    return Api.post('Report/trusted-ticket');
};

const generateAuthToken = () => {
    return Api.post('Report/generate-auth');
};

export default {
    getTableauTicket,
    generateAuthToken
}
