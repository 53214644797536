// Source: https://stackoverflow.com/a/8358141/976042
export function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber || phoneNumber === '') {
        return '';
    }

    const cleaned = ('' + phoneNumber.replace(/\D/g, ''));
    const phoneNumberParts = cleaned.match(/^(\d{3})?(\d{3})(\d{4})$/);
    if (phoneNumberParts) {
        return phoneNumberParts[1] !== undefined
            ? `(${phoneNumberParts[1]}) ${phoneNumberParts[2]}-${phoneNumberParts[3]}`
            : `${phoneNumberParts[2]}-${phoneNumberParts[3]}` // No area code...
    }

    // If it wasn't a "valid" phone, just output it as it came in
    return phoneNumber;
}

export function formatDate(date) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(Date.parse(date)).toLocaleDateString('en-US', options);
}

export function formatJobFamily(jobFamily) {
    if (jobFamily) {
      return jobFamily.split('-')[1].trim();
    }
  }

// case-insensitive helper function for strings searches
export function contains(value, searchFor) {
    var v = (value || '').toLowerCase();
    var v2 = searchFor;
    if (v2) {
        v2 = v2.toLowerCase();
    }
    return v.indexOf(v2) > -1;
}

export function formatName(employee) {
    if (!employee) {
        return '';
    }

    const name = `${employee.preferredName ? employee.preferredName : employee.firstName} ${employee.lastName}`;
    return name;
}

export function resolveProfileImage(employee, forceRefresh) {
    let url = `${employee.imageFileUrl}`;

    // VAN-104 we can't pass additional params when pre-signing AWS S3 URLs (without knowing their exact value)
    if (forceRefresh && url.indexOf('X-Amz-Security-Token') < 1) {
        url = `${url}?ts=${new Date().getTime()}`
    }

    return url;
}

export function objectToQueryParams(obj) {
    return Object.keys(obj)
    .filter(x => obj[x])
    .map(key => `${key}=${obj[key]}`)
    .join('&');
}

/**
 * Converts an array of objects to a "keyed-object"
 * @param {Array} array - The source array
 * @param {*} key - the key for the objects
 * @returns A new object
 */
// Source: https://codezup.com/5-ways-to-convert-array-of-objects-to-object-in-javascript/
export function arrayToObject(array, key) {
    const result = array.reduce((obj, item) => {
        obj[item[key]] = item;
        return obj;
    }, {});

    return result;
};

//------------------------------
// CAN BE REMOVED AFTER WE LOAD LOCATIONS-- this is just for stubbing fake location data
// based on https://gist.github.com/tkon99/4c98af713acc73bed74c
function capFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

export function generateName() {
    var name1 = ["greater", "west", "east", "north", "little", "outer", "inner", "isla", "south", "upper", "old", "grand"];

    var name2 = ["arda", "arnor", "beleriand", "erebor", "eriador", "fangorn", "gladden", "gondor", "mirkwood", "rivendell", "rohan", "shire"];

    return capFirst(name1[getRandomInt(0, name1.length)]) + ' ' + capFirst(name2[getRandomInt(0, name2.length)]);
}
//-----------------------------
