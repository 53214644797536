import {Api} from '../api'

const getAllCategories = () => {
    return Api.get("/Questionnaire/categories");
};

const getCategoriesRespondedTo = (employeeId) => {
    return Api.get(`/Questionnaire/responses/categoryresponses?employeeId=${employeeId}`);
};

const getCategory = (categoryId) => {
    return Api.get(`/Questionnaire/categories/${categoryId}`);
};

const getAllQuestions = () => {
    return Api.get("/Questionnaire/questions");
};

const saveResponses = (responses) => {
    return Api.post("/Questionnaire/responses", responses);
};

const getAllResponsesForEmployee = () => {
    var responses = Api.get('/Questionnaire/responses');
    return responses;
};

const getCategoryResponsesForEmployee = (categoryId) => {
    var responses = Api.get(`/Questionnaire/categories/${categoryId}/responses`);
    return responses;
};

export default {
  getAllCategories,
  getCategoriesRespondedTo,
  getCategory,
  getAllQuestions,
  getAllResponsesForEmployee,
  getCategoryResponsesForEmployee,
  saveResponses
};