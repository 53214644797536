import React from 'react';
import PropTypes from 'prop-types';
import AnchorScrollLink from '../common/scrollLink';

import './profileContextNav.scss';

export default function ProfileContextNav(props) {
    const { groups } = props;

    return (
        <div className="profile-context-nav">
            <h3>Jump to...</h3>
            
            <ul>
                <li><AnchorScrollLink elementId="section-contact-info" linkText="Contact Info" /></li>
                <li><AnchorScrollLink elementId="section-about" linkText="About" /></li>
                {groups.map(group => (
                    <li key={`section-link-${group.id}`}>
                        <AnchorScrollLink
                            elementId={`section-awe-group-${group.id}`}
                            linkText={group.name}
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
};

ProfileContextNav.propTypes = {
    groups: PropTypes.array,
};
