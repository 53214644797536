import { Api } from '../api';

const getGroups = (rootId) => {
    return Api.get(`/ProfileQuestions/groups?rootId=${rootId}`)
};

const getQuestions = (groupId) => {
    return Api.get(`/ProfileQuestions/${groupId}`);
};

const getResponses = (groupId) => {
    return Api.get(`/ProfileQuestions/${groupId}/responses`);
};

const getResponsesForUser = (groupId, userId) => {
    return Api.get(`/ProfileQuestions/${groupId}/responses?userId=${userId}`);
};

const getResponseSummary = (groupId) => {
    return Api.get(`/ProfileQuestions/${groupId}/response-summary`);
};

const verifyQuestionsCompleted = async (groupId) => {
    const responseSummary = await getResponseSummary(groupId);
    return responseSummary.isComplete;
};

const updateResponses = (groupId, responses) => {
    return Api.put(`/ProfileQuestions/${groupId}/responses`, responses);
};

export default {
    getGroups,
    getQuestions,
    getResponses,
    getResponsesForUser,
    getResponseSummary,
    verifyQuestionsCompleted,
    updateResponses
};
