import React from 'react'

// constants
import { AgencyTabIds } from '../../core/AgencyTabs';

// styling
import './GeneratedSignature.scss';

function GeneratedSignature({
    signatureInfo
}) {
    return (
        <div id="generated-signature">
            { signatureInfo.Type === AgencyTabIds.omceg ? 
                (
                    <div>
                        <table
                            vlink="#000000"
                            className="sigtable"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            padding: "0px 0px 0px 0px",
                                            borderStyle: "none none none none",
                                            borderCollapse: "collapse",
                                            lineHeight: '14px',
                                            msoLineHeightRule: "exactly",
                                            webkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%",
                                            whiteSpace: "nowrap"
                                        }}
                                        valign="top"
                                    >
                                        <img src="/assets/img/oeg-logo.png" alt="Omnicom Experiential Group" style={{ borderStyle: "none", borderWidth: 0 }} />
                                        <br />
                                        <br />

                                        <span style={{ fontWeight: "bold", fontSize: "14.6px", color: "#000000" }}>
                                            {signatureInfo.Name} |
                                        </span>
                                        
                                        { signatureInfo.OfficePhone !== "" && signatureInfo.OfficePhone !== null ? 
                                            (
                                                <span style={{ fontSize: "14.6px", color: "#000000" }}>
                                                    {" "}Office {signatureInfo.OfficePhone} |
                                                </span>
                                            )
                                        : "" }

                                        { signatureInfo.MobilePhone !== "" && signatureInfo.MobilePhone !== null ? 
                                            (
                                                <span style={{ fontSize: "14.6px", color: "#000000" }}>
                                                    {" "}Mobile {signatureInfo.MobilePhone} |
                                                </span>
                                            )
                                        : "" }

                                        <span style={{ fontSize: "14.6px", color: "#000000" }}>
                                            <a href="http://www.omceg.com" style={{ color: "#0000EE", whiteSpace: "nowrap" }}>
                                                {" "}omceg.com
                                            </a>
                                            <br />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            :
                (
                    <div>
                        <meta name="format-detection" content="telephone=no" />

                        <table
                            vlink="#000000"
                            className="sigtable"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            padding: "0px 0px 0px 0px",
                                            borderStyle: "none none none none",
                                            borderCollapse: "collapse",
                                            lineHeight: "14px",
                                            msoLineHeightRule: "exactly",
                                            webkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%"
                                        }}
                                        valign="top"
                                    >
                                        <span
                                            style={{
                                                color: "#000000",
                                                fontSize: 14,
                                                fontFamily: '"Arial",sans-serif',
                                                fontWeight: "bold"
                                            }}
                                        >
                                            <b>{signatureInfo.Name}</b>
                                        </span>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td
                                        style={{
                                            padding: "0px 0px 0px 0px",
                                            borderStyle: "none none none none",
                                            borderCollapse: "collapse",
                                            lineHeight: "11px",
                                            msoLineHeightRule: "exactly",
                                            WebkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%",
                                            color: "#000000",
                                            fontSize: 11
                                        }}
                                        valign="top"
                                    >
                                        { signatureInfo.Pronouns !== "" && signatureInfo.Pronouns !== null ? 
                                            (
                                                <div>
                                                    <span style={{ fontFamily: '"Times New Roman",serif' }}>
                                                        <i>{signatureInfo.Pronouns}</i>
                                                    </span>
                                                    <br />
                                                    <br />
                                                </div>
                                            )
                                        : ""}

                                        { signatureInfo.Title !== "" && signatureInfo.Title !== null ? 
                                            (
                                                <div>
                                                    <span style={{ fontFamily: '"Arial",sans-serif', fontWeight: "500" }}>
                                                        <b>{signatureInfo.Title}</b>
                                                    </span>
                                                    <br />
                                                    <br />
                                                </div>
                                            )
                                        : ""}

                                        { signatureInfo.OfficePhone !== "" && signatureInfo.OfficePhone !== null ? 
                                            (
                                                <div>
                                                    <span
                                                        style={{
                                                            fontFamily: '"Arial",sans-serif',
                                                            margin: 0,
                                                            padding: 0,
                                                            fontWeight: "500"
                                                        }}
                                                    >
                                                        <b>O</b>
                                                    </span>{" "}
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }}>
                                                        <i>{signatureInfo.OfficePhone}</i>
                                                    </span>
                                                    <br />
                                                </div>
                                            )
                                        : ""}
                                        
                                        { signatureInfo.MobilePhone !== "" && signatureInfo.MobilePhone !== null ? 
                                            (
                                                <div>
                                                    <span
                                                        style={{
                                                            fontFamily: '"Arial",sans-serif',
                                                            margin: 0,
                                                            padding: 0,
                                                            fontWeight: "500"
                                                        }}
                                                    >
                                                        <b>M</b>
                                                    </span>{" "}
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }}>
                                                        <i>{signatureInfo.MobilePhone}</i>
                                                    </span>
                                                    <br />
                                                </div>
                                            )
                                        : ""}

                                        { (signatureInfo.OtherPhoneLabel !== "" && signatureInfo.OtherPhoneLabel !== null) && (signatureInfo.OtherPhoneNumber !== "" && signatureInfo.OtherPhoneNumber !== null) ? 
                                            (
                                                <div>
                                                    <span
                                                        style={{
                                                            fontFamily: '"Arial",sans-serif',
                                                            margin: 0,
                                                            padding: 0,
                                                            fontWeight: "500"
                                                        }}
                                                    >
                                                        <b>{signatureInfo.OtherPhoneLabel === "Fax" ? "F" : signatureInfo.OtherPhoneLabel}</b>
                                                    </span>{" "}
                                                    <span style={{ fontFamily: '"Arial",sans-serif' }}>
                                                        <i>{signatureInfo.OtherPhoneNumber}</i>
                                                    </span>
                                                    <br />
                                                </div>
                                            )
                                        : ""}

                                        { signatureInfo.Type === AgencyTabIds.gmr ?
                                            (
                                                <br />
                                            )
                                        : "" }

                                        { signatureInfo.Type === AgencyTabIds.gmr ?
                                            (
                                                <div>
                                                    <span
                                                        style={{
                                                            fontFamily: '"Times New Roman",serif',
                                                            fontStyle: "italic",
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    >
                                                        <i>We create memories that matter.</i>
                                                    </span>
                                                    <br />
                                                </div>
                                            )
                                        : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <br />

                        <table
                            vlink="#000000"
                            className="imgtable"
                            style={{ height: 32 }}
                            height={32}
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            width: 32,
                                            height: 32,
                                            padding: "0px 0px 0px 0px",
                                            borderStyle: "none none none none",
                                            borderCollapse: "collapse",
                                            msoLineHeightRule: "exactly",
                                            webkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%"
                                        }}
                                        width={32}
                                        valign="bottom"
                                        height={32}
                                    >
                                        { signatureInfo.Type === AgencyTabIds.gmr ?
                                            (
                                                <a href="https://gmrmarketing.com">
                                                    <img
                                                        src="/assets/img/gmr-logo.png"
                                                        alt=" "
                                                        style={{
                                                            display: "block",
                                                            borderStyle: "none",
                                                            border: "none",
                                                            borderWidth: 0,
                                                            width: "32px !important",
                                                            height: "32px !important",
                                                            background: "#000000"
                                                        }}
                                                        width={32}
                                                        height={32}
                                                    />
                                                </a>
                                            )
                                        : ""}

                                        { signatureInfo.Type === AgencyTabIds.storyHouse ?
                                            (
                                                <a href="https://thestoryhouse-la.com/">
                                                    <img
                                                        src="/assets/img/sh-logo.png"
                                                        alt=" "
                                                        style={{
                                                            display: "block",
                                                            borderStyle: "none",
                                                            border: "none",
                                                            borderWidth: 0,
                                                            width: "32px !important"
                                                        }}
                                                        width={32}
                                                    />
                                                </a>
                                            )
                                        : ""}
                                    </td>

                                    <td
                                        style={{
                                            width: 11,
                                            height: 32,
                                            padding: "0px 0px 0px 0px",
                                            borderStyle: "none none none none",
                                            borderCollapse: "collapse",
                                            msoLineHeightRule: "exactly",
                                            WebkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%"
                                        }}
                                        width={11}
                                        height={32}
                                    ></td>

                                    <td
                                        style={{
                                            height: 32,
                                            padding: "0px 0px 0px 0px",
                                            borderStyle: "none none none none",
                                            borderCollapse: "collapse",
                                            lineHeight: "11px",
                                            msoLineHeightRule: "exactly",
                                            WebkitTextSizeAdjust: "100%",
                                            msTextSizeAdjust: "100%"
                                        }}
                                        valign="bottom"
                                        height={32}
                                    >
                                        <table
                                            vlink="#000000"
                                            className="imgtable"
                                            style={{ height: 32 }}
                                            height={32}
                                            cellSpacing={0}
                                            cellPadding={0}
                                            border={0}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            padding: "0px 0px 0px 0px",
                                                            borderStyle: "none none none none",
                                                            borderCollapse: "collapse",
                                                            lineHeight: "11px",
                                                            msoLineHeightRule: "exactly",
                                                            WebkitTextSizeAdjust: "100%",
                                                            msTextSizeAdjust: "100%"
                                                        }}
                                                        valign="bottom"
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#000000",
                                                                fontSize: 11,
                                                                fontFamily: '"Arial",sans-serif',
                                                                fontWeight: "500"
                                                            }}
                                                        >
                                                            { signatureInfo.Type === AgencyTabIds.gmr ?
                                                                (<b>gmrmarketing.‍com</b>)
                                                            : ""}

                                                            { signatureInfo.Type === AgencyTabIds.storyHouse ?
                                                                (<b>thestoryhouse-la.com</b>)
                                                            : ""}
                                                        </span>
                                                        <br />
                                                        <span
                                                            style={{
                                                                color: "#000000",
                                                                fontSize: 11,
                                                                fontFamily: '"Times New Roman",serif',
                                                                fontStyle: "italic"
                                                            }}
                                                        >
                                                            <i>A member of Omnicom Experiential Group</i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }
        </div>
    )  
}

export default GeneratedSignature;