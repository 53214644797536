import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

export default function Rating1to6Response(props) {    
    const { question, response, onChangeResponse } = props;
    const [ responseValue, setResponseValue ] = useState('');

    useEffect(() => {
        setResponseValue(response);
    }, [response]);

    const handleChange = (e) => {
        const fieldvalue = e.target.value;
        setResponseValue(fieldvalue);
        onChangeResponse(parseInt(question.id), fieldvalue);
    };

    return (
        <RadioGroup row value={responseValue} onChange={handleChange}>
            <FormControlLabel value="1" control={<Radio />} label="(Most) 1" style={{ paddingLeft: 0 }} />
            <FormControlLabel value="2" control={<Radio />} label="2" style={{ paddingLeft: 0 }} />
            <FormControlLabel value="3" control={<Radio />} label="3" style={{ paddingLeft: 0 }} />
            <FormControlLabel value="4" control={<Radio />} label="4" style={{ paddingLeft: 0 }} />
            <FormControlLabel value="5" control={<Radio />} label="5" style={{ paddingLeft: 0 }} />
            <FormControlLabel value="6" control={<Radio />} label="6 (Least)" style={{ paddingLeft: 0 }} />
        </RadioGroup>
    )
}

Rating1to6Response.propTypes = {
    question: PropTypes.object.isRequired,
    response: PropTypes.string,
    onChangeResponse: PropTypes.func,//.isRequired,
};