import React, { useState, useEffect } from 'react';
import { FormControl, Select, MenuItem, TextField } from '@material-ui/core';

export default function DropdownResponse(props) {
    const { question, response, onChangeResponse } = props;
    const [ responseValue, setResponseValue ] = useState('');
    const [ showManualInput, setShowManualInput ] = useState(false);
    const [ manualInputValue, setManualInputValue ] = useState('');

    useEffect(() => {
        const optionValues = question.options.map(x => x.optionValue);

        if (question.allowManualInput && !optionValues.includes(response)) {
            setShowManualInput(true);
            setManualInputValue(response);

            const manualInputOption = question.options.find(x => x.showManualInput);
            if (manualInputOption) {
                setResponseValue(manualInputOption.optionValue);
            }
        } else {
            setResponseValue(response);

            setShowManualInput(false);
            setManualInputValue('');
        }
    }, [response]);

    const handleChange = (e) => {
        const fieldValue = e.target.value;
        setResponseValue(fieldValue);

        const selectedOption = question.options.find(x => x.optionValue === fieldValue);
        if (selectedOption && selectedOption.showManualInput && question.allowManualInput) {
            setShowManualInput(true);
        } else {
            setShowManualInput(false);
            onChangeResponse(question.id, fieldValue);
        }
    };

    const handleManualInputChange = (e) => {
        setManualInputValue(e.target.value);
    }

    const handleManualInputBlur = () => {        
        onChangeResponse(question.id, manualInputValue);
    }

    return (
        <FormControl variant="outlined">
          <Select
              value={responseValue}
              aria-label={question.questionText}
              onChange={handleChange}
          >
              {question.options.map(option => (
                  <MenuItem
                      key={option.id}
                      value={option.optionValue}
                  >
                      {option.optionText}
                  </MenuItem>
              ))}
          </Select>

            {
                showManualInput
                ? (
                    <TextField
                        variant="outlined"
                        label={question.manualInputLabel}
                        style={{ width: `100%`, marginTop: '1rem' }}
                        multiline={true}
                        rows="6"
                        value={manualInputValue}
                        onChange={handleManualInputChange}
                        onBlur={handleManualInputBlur}
                    />
                )
                : null
            }
        
        </FormControl>
    )
}