import { useState } from 'react';

const getErrorCode = (error) => {
    if (error) {
        return parseInt(error.status ?? 0);
    } else {
        return 0;
    }
};

const getErrorMessage = (error) => {
    if (error) {
        return error.title ?? null;
    } else {
        return null;
    }
};

export function useError() {
    const [errorObj, setErrorObj] = useState({
        error: false,
        code: 0,
        message: null,
        detail: null
    });

    function setError(error, errObj, info) {
        setErrorObj({
            error: error,
            code: getErrorCode(errObj),
            message: getErrorMessage(errObj),
            detail: info
        });
    }

  return [errorObj, setError];
}