import React, { useState } from 'react'
import PropTypes from 'prop-types';

// components
import { FormControl, InputLabel, Select, MenuItem, TextField, Tabs, Tab, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PhoneInput from 'react-phone-input-2'
import Button from '@material-ui/core/Button';
import PronounSelector from '../user/PronounSelector';
import TabPanel from '../tabs/TabPanel';
import GeneratedSignature from './GeneratedSignature';
import { formatPhoneNumber } from '../../core/utils';

// constants
import { AgencyTabIds, AgencyTabNames } from '../../core/AgencyTabs';

// icons
import IconClose from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// styling
import 'react-phone-input-2/lib/material.css'
import './SignatureGenerator.scss';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function checkForUSCountryCode(number) {
    return (number.replace(/\D/g,'').length === 10 && number.charAt(0) !== 1)
}

function checkIfStringIsEmpty(value) {
    return (value.trim() === "" || value === null || value === undefined)
}
  
function SignatureGenerator({
    employee,
    handleClose
}) {
    const { enqueueSnackbar } = useSnackbar();

    const [error, setError] = useState(false);
    const [signatureGenerate, setSignatureGenerate] = useState(false);
    const [signatureInfo, setSignatureInfo] = useState({
        Name: `${checkIfStringIsEmpty(employee.preferredName) ? employee.firstName : employee.preferredName} ${employee.lastName}`,
        Title: employee.title,
        OfficePhone: checkForUSCountryCode(employee.officePhone) ? `+1 ${formatPhoneNumber(employee.officePhone)}` : employee.officePhone,
        MobilePhone: checkForUSCountryCode(employee.mobilePhone) ? `+1 ${formatPhoneNumber(employee.mobilePhone)}` : employee.mobilePhone,
        OtherPhoneLabel: "",
        OtherPhoneNumber: "",
        Pronouns: employee.preferredPronoun,
        Type: (employee.agencyDisplayName === AgencyTabNames.storyHouse ? AgencyTabIds.storyHouse : AgencyTabIds.gmr)
    });

    // Components can't always give us back event object that has a name that we can set - have to pass manually
    const handleComponentFieldChange = (value, field) => {
        setSignatureInfo(info => ({...info, [field]: value}));
    };

    const handleFieldChange = (e) => {
        const target = e.target;

        setSignatureInfo(info => ({...info, [target.name]: target.value}));
        
        if (target.name === "Name" && (target.value === "" || target.value === null)) {
            setError(true);
        } else if ((target.name === "Name" && (target.value !== "" && target.value !== null))) {
            setError(false);
        }
    }
    
    const handleGenerateSignature = () => {
        if (signatureInfo.Name === "" || signatureInfo.Name === null) {
            enqueueSnackbar(`A Name is required to generate an email signature`, { 'variant': 'error' });
            setError(true);
        } else {
            setSignatureGenerate(!signatureGenerate);
        }
    };

    // Copy entire signature - no need to highlight
    const copySignature = () => {
        const codeEle = document.getElementById('generated-signature');
        const selection = window.getSelection();
        const range = document.createRange();

        range.selectNodeContents(codeEle);
        selection.removeAllRanges();
        selection.addRange(range);
        
        document.execCommand('copy');
    }

    return (
        <div className="modal" style={{ minWidth: 200 }}>
            <IconClose className="close-modal" onClick={() => handleClose(false)} />
            <div className="modal-content signature-generator-content">  
                <h2>Generate Your Email Signature</h2>

                { signatureGenerate ? (
                    <div>
                        <div 
                            id="signature-container"
                            style={{
                                overflowX: 'auto'
                            }}
                        >
                            <FileCopyIcon className="copy-content" onClick={copySignature} />
                            <GeneratedSignature signatureInfo={signatureInfo}/>
                        </div>

                        <div className="actions">
                            <Button
                                variant="contained"
                                color="default" 
                                disabled={false}
                                onClick={handleGenerateSignature}
                            >
                                <ArrowBackIcon/> Back
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '30px' }}>
                            <Tabs value={signatureInfo.Type} onChange={(e, value) => handleComponentFieldChange(value, "Type")} aria-label="basic tabs example">
                                <Tab label="GMR" {...a11yProps(0)} />
                                <Tab label="OMCEG" {...a11yProps(1)} />
                                <Tab label="STORYHOUSE" {...a11yProps(2)} />
                            </Tabs>
                        </Box>

                        <TabPanel value={signatureInfo.Type} index={0}>
                            <TextField
                                className="name"
                                required={true}
                                error={error}
                                variant="outlined"
                                label="Name"
                                name="Name"
                                style={{ width: `100%`, maxWidth: 350, minWidth: 200 }}
                                minRows="1"
                                value={signatureInfo.Name}
                                onChange={handleFieldChange}
                            />

                            <TextField
                                className="title"
                                variant="outlined"
                                label="Title"
                                name="Title"
                                style={{ width: `100%`, maxWidth: 400, minWidth: 200 }}
                                minRows="1"
                                value={signatureInfo.Title}
                                onChange={handleFieldChange}
                            />

                            <PhoneInput
                                country='us'
                                preferredCountries={['us']}
                                style={{ width: `100%`, maxWidth: 300, marginBottom: '20px', minWidth: 200 }}
                                value={signatureInfo.OfficePhone}
                                onChange={(value, country, e, formattedValue) => handleComponentFieldChange(formattedValue, "OfficePhone")}
                                specialLabel='Office Phone'
                                inputProps={{
                                    name: 'OfficePhone',
                                    className: 'form-control office-phone',
                                    style: { width: '100%' }
                                }}
                            />

                            <PhoneInput
                                country='us'
                                preferredCountries={['us']}
                                style={{ width: `100%`, maxWidth: 300, marginBottom: '20px', minWidth: 200 }}
                                value={signatureInfo.MobilePhone}
                                onChange={(value, country, e, formattedValue) => handleComponentFieldChange(formattedValue, "MobilePhone")}
                                specialLabel='Mobile Phone'
                                inputProps={{
                                    name: 'MobilePhone',
                                    className: 'form-control mobile-phone',
                                    style: { width: '100%' }
                                }}
                            />

                            <p>Other Phone</p>

                            <FormControl className="otherPhone" variant="outlined" style={{ marginBottom: '20px' }}>
                                <InputLabel id="otherPhoneLabel" style={{ whiteSpace: 'nowrap', padding: '0 5px' }}>Label</InputLabel>
                                <Select
                                    labelId="phoneLabel"
                                    label="Label"
                                    name="OtherPhoneLabel"
                                    id="selectLabel"
                                    className="label-select"
                                    value={signatureInfo.OtherPhoneLabel}
                                    onChange={handleFieldChange}
                                >
                                    <MenuItem value="Admin">Admin</MenuItem>
                                    <MenuItem value="Main Office">Main Office</MenuItem>
                                    <MenuItem value="Fax">Fax</MenuItem>
                                </Select>
                            </FormControl>

                            <PhoneInput
                                country={'us'}
                                style={{ width: `100%`, maxWidth: 300, display: 'inline-flex', marginBottom: '20px', minWidth: 200 }}
                                value={signatureInfo.OtherPhoneNumber}
                                onChange={(value, country, e, formattedValue) => handleComponentFieldChange(formattedValue, "OtherPhoneNumber")}
                                specialLabel='Number'
                                inputProps={{
                                    name: 'OtherPhoneNumber',
                                    className: 'form-control number',
                                    style: { width: '100%' }
                                }}
                            />

                            <PronounSelector pronouns={signatureInfo.Pronouns} onPronounsChanged={(value) => handleComponentFieldChange(value, "Pronouns")} />
                        </TabPanel>

                        <TabPanel value={signatureInfo.Type} index={1}>
                            <TextField
                                className="name"
                                required={true}
                                error={error}
                                variant="outlined"
                                label="Name"
                                name="Name"
                                style={{ width: `100%`, maxWidth: 350 }}
                                minRows="1"
                                value={signatureInfo.Name}
                                onChange={handleFieldChange}
                            />

                            <PhoneInput
                                country='us'
                                preferredCountries={['us']}
                                style={{ width: `100%`, maxWidth: 300, marginBottom: '20px', minWidth: 200 }}
                                value={signatureInfo.OfficePhone}
                                onChange={(value, country, e, formattedValue) => handleComponentFieldChange(formattedValue, "OfficePhone")}
                                specialLabel='Office Phone'
                                inputProps={{
                                    name: 'OfficePhone',
                                    className: 'form-control office-phone',
                                    style: { width: '100%' }
                                }}
                            />

                            <PhoneInput
                                country='us'
                                preferredCountries={['us']}
                                style={{ width: `100%`, maxWidth: 300, marginBottom: '20px', minWidth: 200 }}
                                value={signatureInfo.MobilePhone}
                                onChange={(value, country, e, formattedValue) => handleComponentFieldChange(formattedValue, "MobilePhone")}
                                specialLabel='Mobile Phone'
                                inputProps={{
                                    name: 'MobilePhone',
                                    className: 'form-control mobile-phone',
                                    style: { width: '100%' }
                                }}
                            />
                        </TabPanel>

                        <TabPanel value={signatureInfo.Type} index={2}>
                            <TextField
                                className="name"
                                required={true}
                                error={error}
                                variant="outlined"
                                label="Name"
                                name="Name"
                                style={{ width: `100%`, maxWidth: 350 }}
                                minRows="1"
                                value={signatureInfo.Name}
                                onChange={handleFieldChange}
                            />

                            <TextField
                                className="title"
                                variant="outlined"
                                label="Title"
                                name="Title"
                                style={{ width: `100%`, maxWidth: 400 }}
                                minRows="1"
                                value={signatureInfo.Title}
                                onChange={handleFieldChange}
                            />

                            <PhoneInput
                                country='us'
                                preferredCountries={['us']}
                                style={{ width: `100%`, maxWidth: 300, marginBottom: '20px', minWidth: 200 }}
                                value={signatureInfo.OfficePhone}
                                onChange={(value, country, e, formattedValue) => handleComponentFieldChange(formattedValue, "OfficePhone")}
                                specialLabel='Office Phone'
                                inputProps={{
                                    name: 'OfficePhone',
                                    className: 'form-control office-phone',
                                    style: { width: '100%' }
                                }}
                            />

                            <PhoneInput
                                country='us'
                                preferredCountries={['us']}
                                style={{ width: `100%`, maxWidth: 300, marginBottom: '20px', minWidth: 200 }}
                                value={signatureInfo.MobilePhone}
                                onChange={(value, country, e, formattedValue) => handleComponentFieldChange(formattedValue, "MobilePhone")}
                                specialLabel='Mobile Phone'
                                inputProps={{
                                    name: 'MobilePhone',
                                    className: 'form-control mobile-phone',
                                    style: { width: '100%' }
                                }}
                            />

                            <p>Other Phone</p>

                            <FormControl className="otherPhone" variant="outlined" style={{ marginBottom: '20px' }}>
                                <InputLabel id="otherPhoneLabel" style={{ whiteSpace: 'nowrap', padding: '0 5px' }}>Label</InputLabel>
                                <Select
                                    labelId="phoneLabel"
                                    label="Label"
                                    name="OtherPhoneLabel"
                                    id="selectLabel"
                                    className="label-select"
                                    value={signatureInfo.OtherPhoneLabel}
                                    onChange={handleFieldChange}
                                >
                                    <MenuItem value="Admin">Admin</MenuItem>
                                    <MenuItem value="Main Office">Main Office</MenuItem>
                                    <MenuItem value="Fax">Fax</MenuItem>
                                </Select>
                            </FormControl>

                            <PhoneInput
                                country={'us'}
                                style={{ width: `100%`, maxWidth: 300, display: 'inline-flex', marginBottom: '20px', minWidth: 200 }}
                                value={signatureInfo.OtherPhoneNumber}
                                onChange={(value, country, e, formattedValue) => handleComponentFieldChange(formattedValue, "OtherPhoneNumber")}
                                specialLabel='Number'
                                inputProps={{
                                    name: 'OtherPhoneNumber',
                                    className: 'form-control number',
                                    style: { width: '100%' }
                                }}
                            />

                            <PronounSelector pronouns={signatureInfo.Pronouns} onPronounsChanged={(value) => handleComponentFieldChange(value, "Pronouns")} />
                        </TabPanel>

                        <div className="actions">
                            <Button
                                variant="contained"
                                color="primary" 
                                disabled={false}
                                onClick={handleGenerateSignature}
                            >
                                Generate Signature
                            </Button>
                        </div>
                    </div>
                ) }
            </div>
        </div>
    )
}

SignatureGenerator.propTypes = {
    employee: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default SignatureGenerator;