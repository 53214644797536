import { useCallback, useState } from "react";
import { useLocation } from "react-router"
import qs from 'query-string';

// Source: https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f

const updateQuerystring = (value) => {
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${value}`;
    window.history.pushState({path: url}, '', url);
};

const setQueryStringValue = (key, value, queryString = window.location.search) => {
    const values = qs.parse(queryString);
    // Update the values
    const newQuery = qs.stringify({...values, [key]: value });
    updateQuerystring(newQuery);
};

export function useQueryString(key, initialValue) {
    const location = useLocation();
    const params = qs.parse(location.search);

    const [value, setValue] = useState(params[key] || initialValue);
    const onSetValue = useCallback(newValue => {
        setValue(newValue);
        setQueryStringValue(key, newValue);
    }, [key]);

    return [value, onSetValue];
};