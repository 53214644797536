import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import ReactGA from 'react-ga';

import CacheBuster from 'react-cache-buster';
import Backdrop from '@material-ui/core/Backdrop';

import config from './config';
import './css/App.scss';
import Header from './components/global.header';
import Navigation from './components/navigation/navigation';
import Footer from './components/global.footer';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import {
  Home,
  Directory,
  Profile,
  ViewProfile,
  SeatingMaps,
  IntroQuestions,
  Survey,
  Category,
  SurveyDashboard, // TODO: need to lock access to this for all but a select list of users.
  Login,
  PageNotFound
} from './views';

// add close action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}

const authConfig = config.auth;

const trackPageView = (location) => {
  if (!location) {
    location = window.location;
  }
  
  ReactGA.set({ page: location.path }); // Set user's current page
  ReactGA.pageview(location.pathname); // Record current path
};

ReactGA.initialize(config.googleAnalytics.siteId);
trackPageView(); // Track the initial page load

const oktaAuth = new OktaAuth(authConfig);

// Can be used to test the authState changes
// oktaAuth.authStateManager.subscribe(authState => {
//   console.log('authState', authState);
// });

const RoutableApp = () => {

  const handleUnAuthenticatedRequest = () => {
    // Ideally we would use react router (history.push())
    // But there's an issue with redirecting and updating state
    document.location = '/login';
  }

  const { listen, replace } = useHistory();

  useEffect(() => {
    // Track the page view on route changes
    const unlisten = listen((location) => {
      trackPageView(location);
    });

    return unlisten;
  }, [listen]);

  const restoreOriginalUrl = async (_oktaAuth, originalUri) => {
    replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <CacheBuster 
    currentVersion={process.env.REACT_APP_VERSION} // don't expose package.json call from .env
    isEnabled={true} // If false, the library is disabled.
    isVerboseMode={true} // If true, the library writes verbose logs to console.
    loadingComponent={<Backdrop open={true} children={<div>LOADING</div>}/>} // Optional component to display at the time of new version check.
    >
      <CookiesProvider>
        <SnackbarProvider
              maxSnack={3}
              ref={notistackRef}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
              autoHideDuration={2000}
              action={(key) => (
                    <IconButton
                                key="close"
                                title="close"
                                color="inherit"
                                className="closeSnackbar"
                                onClick={onClickDismiss(key)}
                            >
                                <CloseIcon />
                            </IconButton>
                )}
          >
            <Security
              oktaAuth={oktaAuth}
              onAuthRequired={handleUnAuthenticatedRequest}
              restoreOriginalUri={restoreOriginalUrl}
            >
      
              <Navigation />
              <div id="body-content">
                <Header />
                <Switch>
                  <Route path="/login" component={Login} />
                  <SecureRoute exact path="/directory" component={Directory} />              
                  <SecureRoute exact path="/profile" component={Profile} />
                  <SecureRoute exact path="/employee/:employeeId" component={ViewProfile} />
                  <SecureRoute exact path="/seatingmaps" component={SeatingMaps} />
                  <SecureRoute exact path="/introquestions/questions/:groupId" component={IntroQuestions} />              
                  <SecureRoute exact path="/survey/category/:catId" children={<Category />}/>
                  <SecureRoute exact path="/survey" component={Survey}/>
                  <SecureRoute exact path="/surveydashboard" component={SurveyDashboard}/>
                  <SecureRoute exact path="/" component={Home} />
                  <SecureRoute path="*" component={PageNotFound} />
                  <Route path={config.auth.callbackPath} component={LoginCallback} />
                  <Redirect to="/"/>
                </Switch>
                <Footer version={process.env.REACT_APP_VERSION}/>
              </div>
            </Security>
        </SnackbarProvider>
      </CookiesProvider>
    </CacheBuster>
  )
}

export default function App() {

  return (
    <div id="app">

      <Router>
        <RoutableApp />
      </Router>

    </div>

  );

}
