import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';

export default function TextResponse(props) {
    const { question, response, isRequired, onChangeResponse, isMultiline, isDisabled } = props;
    const [ responseValue, setResponseValue ] = useState('');

    useEffect(() => {
        setResponseValue(response);
    }, [response]);

    const handleChange = (e) => {
        const fieldvalue = e.target.value;
        setResponseValue(fieldvalue);
    };
    
    const handleUpdate = (e) => {
        const fieldvalue = e.target.value
        onChangeResponse(question.id, fieldvalue);
    }

    return (
        <TextField
            variant="outlined"
            style={{ width: `100%` }}
            multiline={isMultiline}
            required={isRequired}
            minRows="6"
            value={responseValue}
            disabled={isDisabled}
            onChange={handleChange}
            onBlur={handleUpdate}
        />
    )
}