import IntroQuestionGroups from './IntroQuestionGroups';
import ProfileQuestionService from '../core/services/ProfileQuestionService';
import { arrayToObject } from './utils';

/**
 * Returns the top-level (no parent) AWE profile groups
 * @param {Array} allGroups - All possible groups
 * @returns {Array} The top-level AWE groups
 */
export function getTopAweProfileGroups(allGroups) {
    const groups = allGroups.filter(x => x.parentId === IntroQuestionGroups.AnyplaceWorkplaceExperience);
    return groups;
}

/**
 * Gets the groups that have questions with responses
 * @param {Array} groups - The source groups
 * @param {Array} questions - The questions
 * @param {Object} responses = The responses to use to check against
 * @returns {Array} The groups that have responses
 */
 export function getGroupsWithQuestionResponses (groups, questions, responses) {
    if (groups.length === 0 || questions.length === 0 || Object.keys(responses).length === 0) return [];

    const respondedQuestionIds = Object.values(responses)
        .filter(x => x.responseValue && x.responseValue !== null && x.responseValue !== '')
        .map(x => x.questionId);

    let respondedGroupIds = questions.filter(x => respondedQuestionIds.includes(x.id)).map(x => x.subGroupId);

    // these are the subgroupIds of the Comm Preferences Ratings questions
    // they don't behave like the normal questions so we need an extra check for them
    // the Comm Preferences subgroupIds don't match the questionGroup Ids
    const commPrefRatingsQuestionGroupIds = [11,12,13,14,15,16,17,18]; 

    // check if the user answered any of the comm preferences questions
    if (respondedGroupIds.some(id => commPrefRatingsQuestionGroupIds.includes(id))){
        respondedGroupIds.push(3); // 3 is the id of the Communications Preferences parent subgroup. we add it so the responses will show up
    }

    // Use a set to get the distinct group IDs
    const distinctRespondedGroupIds = [...new Set(respondedGroupIds)];

    // the questionGroups for the Profile are IDs from 3 to 8
    const questionGroups = groups.filter(x => distinctRespondedGroupIds.includes(x.id));

    return questionGroups;
};

/**
 * Load the groups, questions and responses for the AWE profile.
 * @param {*} [employeeId] - The employeeId; uses current user if not provided.
 * @returns {object} AWE profile object
 */
export async function loadAweProfile(employeeId) {
    let groups = [], questions = [], responses = [];
    
    await Promise.all([getWorkplaceSurveyGroups(), getWorkplaceSurveyQuestions(), getWorkplaceSurveyResponses(employeeId)])
        .then((apiResponses) => {
            groups = apiResponses[0];
            questions = apiResponses[1];
            responses = apiResponses[2];
        })
        .catch((error) => {
            throw new Error('Error retrieving Workplace profile.');
        });

    return {
        groups,
        questions,
        responses,
    };
};

export function createResponsesObject(responses) {
    const responseObj = arrayToObject(responses, 'questionId'); // We want questionId to be the key so is easy to compare
    return responseObj;
};

const getWorkplaceSurveyGroups = async () => {
    const groups = await ProfileQuestionService.getGroups(IntroQuestionGroups.AnyplaceWorkplaceExperience);
    return groups;
};

const getWorkplaceSurveyQuestions = async () => {
    const questions = await ProfileQuestionService.getQuestions(IntroQuestionGroups.AnyplaceWorkplaceExperience);
    return questions;
};

const getWorkplaceSurveyResponses = async (employeeId) => {
    if (employeeId) {
        return await ProfileQuestionService.getResponsesForUser(IntroQuestionGroups.AnyplaceWorkplaceExperience, employeeId);
    }
    
    return await ProfileQuestionService.getResponses(IntroQuestionGroups.AnyplaceWorkplaceExperience);
};
