/* eslint-disable react-hooks/exhaustive-deps */
// Specifically ignoring these because it's complaining about method deps

import React, { useState, useEffect } from 'react';
import LocationService from '../../core/services/LocationService'
import { useSnackbar } from 'notistack';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.scss';

export default function OfficeLocationTiles(props) {

  const { clickHandler, onlyDisplayMappedOffices } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [offices, setOffices] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  // on load get our offices
  useEffect(() => {
    setIsLoading(true);
    getOfficesWithMaps();
  }, []);

  // call the endpoint to return offices with maps
  // we might not always need maps, but the payload is tiny either way
  const getOfficesWithMaps = () => {
    LocationService.getOfficesWithMaps()
      .then(response => {
        // sort by displayName for now
        response = response.sort((a, b) => { return a.displayName.localeCompare(b.displayName) });

        // removes offices with empty seatingMaps[]
        if (onlyDisplayMappedOffices) {
          response = response.filter(x => { return x.seatingMaps.length > 0 });
        }
      
        setOffices(response);
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        enqueueSnackbar(`Failed to retrieve Offices. Are you connected and logged in?`, { 'variant': 'error' });
        console.error(e);
      });
  };

  return (
    <div>
      <Backdrop className="backdrop" open={isLoading}>
        <CircularProgress />
      </Backdrop>

      <article className="locations">
        {offices.map((ofc) => (
            <div className="location" key={ofc.officeKey} onClick={() => clickHandler(ofc)} style={{backgroundImage: "url('/assets/img/location-tiles/"+ofc.officeKey+".jpg'), url('/assets/img/location-tiles/default.jpg')"}}>
            <p>{ofc.displayName}</p>
          </div>
        ))}
      </article>
    </div>
  );

}