import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@material-ui/core';

import MultipleChoiceResponse from './MultipleChoiceResponse';
import DropdownResponse from './DropdownResponse';
import TextResponse from './TextResponse';
import Rating1to6Response from './Rating1to6Response';
import SmileyRatingResponse from './SmileyRatingResponse';

Question.propTypes = {
    question: PropTypes.object.isRequired,
    responseValue: PropTypes.string,
    validationError: PropTypes.string,
    onResponseChanged: PropTypes.func,
    readOnly: PropTypes.bool,
    isMultiline: PropTypes.bool,
    isDisabled: PropTypes.bool
};

export default function Question(props) {
    const { 
        question, 
        responseValue,
        validationError, 
        onResponseChanged,
        readOnly,
        isMultiline,
        isDisabled
    } = props;

    const renderInput = (question) => {
        switch(question.questionType) {
            case 'Text':
                return (
                    <TextResponse
                        question={question}
                        response={responseValue}
                        isMultiline={isMultiline}
                        isDisabled={isDisabled}
                        onChangeResponse={(questionId, value) => 
                            onResponseChanged(questionId, value)}
                    />
                )
            case 'RadioButtonList':
                return (
                    <MultipleChoiceResponse 
                        question={question}
                        response={responseValue}
                        onChangeResponse={(questionId, value) => 
                            onResponseChanged(questionId, value)}
                    />
                )
            case 'DropdownList':
                return (
                    <DropdownResponse
                        question={question}
                        response={responseValue}
                        onChangeResponse={(questionId, value) => 
                            onResponseChanged(questionId, value)}
                    />
                )
            case 'Rating1_6':
                return (
                    <Rating1to6Response
                        question={question}
                        response={responseValue}
                        onChangeResponse={(questionId, value) => 
                            onResponseChanged(questionId, value)}
                    />
                )
            case 'Rating_Smileys':
                return (
                    <SmileyRatingResponse
                        question={question}
                        response={responseValue}
                        onChangeResponse={(questionId, value) => 
                            onResponseChanged(questionId, value)}
                    />
                )
        }
    };

    const renderResponse = (question) => {
        // We want to show the rating smiley's in readonly mode
        if (question.questionType === 'Rating_Smileys') {
            return (
                <SmileyRatingResponse
                    question={question}
                    response={responseValue}
                    readOnly={readOnly}
                />
            )
        }

        // Otherwise just return the response as text
        return (
            <div className="response-text">
                {responseValue ? responseValue : (<i>No response.</i>)}
            </div>
        );
    };

    // VAN-98 - If we're in readonly mode and don't have a value, don't display anything
    // (and also we still want to show 'empty' responses for the smiley rating questions)
    if (readOnly && question.questionType !== 'Rating_Smileys' && !responseValue) {
        return null;
    }

    return (
        <article className={`question question-${question.id} ${question.isRequired ? 'required-question' : ''}`}>
            <FormGroup>
                <h4>{question.questionText}</h4>

                {/* {validationError === 'missing'
                    ? (<p className="error">This is a required question.</p>)
                    : null } */}

                { readOnly ? renderResponse(question) : renderInput(question)}
            </FormGroup>
        </article>
    );
};