import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import IconArrowLeft from '@material-ui/icons/ArrowLeft';
import Select from '@material-ui/core/Select';

import OfficeLocationTiles from '../../components/officeLocations/OfficeLocationTiles';
import SeatingMap from '../../components/seatingMap/SeatingMap';

import './style.scss';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';


export default function SeatingMaps() {
  const [showLocations, setShowLocations] = useState(true);
  const [selectedOffice, setSelectedOffice] = useState({});
  const [selectedMap, setSelectedMap] = useState(''); // this is just the Id of the selected seating map
  const history = useHistory();

  const handleOfficeSelect = (office) => {
    const externalLinks = {
      "GMR-NC-Charlotte": "https://awe.omceg.com/hub-clt/",
      "GMR-WI-Milwaukee": "https://awe.omceg.com/hub-mke/",
      "Exp-CA-Los Angeles": "https://awe.omceg.com/hub-la/"
    };
  
    const link = externalLinks[office.officeKey];
  
    if (link) {
      window.location.href = link;
    } else {
      setSelectedOffice(office);
      setSelectedMap(office.seatingMaps[0].id); // default to the first map in the list for each office.
      setShowLocations(!showLocations);
    }
  };

  const clearSelection = () => {
    showLocations === true ? setShowLocations(false) : setShowLocations(true);
    // this probably isn't necessary. if it affects performance, let's pull it.
    history.push('/seatingmaps');
  };

  const handleMapSelect = e => {
    setSelectedMap(e.target.value);
  };

  return (

    <div>
      <section className="dashboard card">
        <article>
          <h1>Experience Hubs</h1>

          {showLocations ?
            <div>
              <p>Select an Experience Hub for more information.</p>
              <OfficeLocationTiles onlyDisplayMappedOffices clickHandler={handleOfficeSelect} />
            </div>
            :
            <div>
              <div className="office-label">
                <Button className="button-clear-location" variant="outlined" onClick={clearSelection}><IconArrowLeft /> Back</Button>
                <span className="selected-office">{selectedOffice.displayName}</span>
                {selectedOffice.seatingMaps.length > 1 ?
                  <FormControl className="searchFormControl floor" variant="outlined">
                    <InputLabel id="mapLabel" style={{ whiteSpace: 'nowrap' }}>Floor/Building</InputLabel>

                    <Select
                      key="selectMap"
                      disabled={!setShowLocations}
                      labelId="mapLabel"
                      id="selectMap"
                      style={{ minWidth: 180 }}
                      value={selectedMap}
                      onChange={handleMapSelect}
                    >
                      {selectedOffice && selectedOffice.seatingMaps && selectedOffice.seatingMaps.map(map => (
                        <MenuItem value={map.id} key={map.id}>{map.displayName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  :
                    null
                }
              </div>
              <SeatingMap id={selectedMap}/>
            </div>
          }
        </article>
      </section>
    </div>
  );
}
