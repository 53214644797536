import axios from "axios";

// this could be utilized from anywhere, but is primarily intended to be used by our "services"

// set some defaults for our API calls
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

const makeRequest = async (method, url, data, headers) => {
  // Getting the token directly from local storage to avoid having to 
  // instantiate the "AuthService". I'm not sure this is the "ideal" way to go...
  const oktaTokens = window.localStorage.getItem('okta-token-storage')
    ? JSON.parse(window.localStorage.getItem('okta-token-storage'))
    : null;

  const accessToken = oktaTokens && Object.keys(oktaTokens).length > 0 ? oktaTokens?.accessToken?.accessToken : null;

  // if you had multiple browser tabs open and logged out of one, 
  // the tokens would be gone and navigating could throw errors
  // we should just redirect back to the login since we can't call the API without tokens
  if (accessToken === null) { 
    console.error('No tokens found. Redirecting to login.');
    document.location = "/";
  }

  headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    ...headers, // putting this last so what's passed in will override the defaults
  };

  try {
    
    const response = await instance.request({
      url,
      method,
      data,
      headers
    });

    return response;

  } catch(err) {
    if (err.response){
      console.error(err.response);
      throw err.response;
    } else {
      throw new Error(err);
    }
  }
};

const request = async (method, url, data, headers) => {
  return (await makeRequest(method, url, data, headers)).data;
}

const getPagedRequest =  async (method, url, data, headers) => {
  // do some processing
  var response = await makeRequest(method, url, data, headers);

  var pageResponse = {
    data: response.data,
    pageCount: response.headers['x-pagination-pagecount'],
    total: response.headers['x-pagination-total'],
    page: response.headers['x-pagination-page'],
    pageSize: response.headers['x-pagination-pageSize']
  }

  return pageResponse;
}

export const Api = {
  get: (endpoint, data, headers) => request('get', endpoint, data, headers),
  getPage: (endpoint, data, headers) => getPagedRequest('get', endpoint, data, headers),
  post: (endpoint, data, headers) => request('post', endpoint, data, headers),
  put: (endpoint, data) => request('put', endpoint, data),
  del: (endpoint, data) => request('delete', endpoint, data),
};