import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect } from 'react';

import './style.scss';

const User = (props) => { 
   
  const { clickHandler } = props;
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes
  
  if (!authState.isAuthenticated){
    return null;
  }
  return (
    <div>
      { userInfo && 
        <div className="user-name" onClick={clickHandler}>
          <p>{userInfo.name}</p>
        </div>
      }
    </div>
  );
};

export default User;