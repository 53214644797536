import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { TextField, InputAdornment } from '@material-ui/core';
import IconVpnKey from '@material-ui/icons/VpnKey';

function MfaVerify({
    availableFactors,
    onVerified,
}) {
    const [ selectedFactorId, setSelectedFactorId ] = useState('');

    const [ validationCode, setValidationCode ] = useState('');
    const [ isProcessing, setIsProcessing ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');

    const getAvailableFactors = () => {
        // For now, we are only supporting token factors, not push...
        return availableFactors.filter(x => x.factorType === 'token:software:totp');
    }

    const getSelectedFactor = () => {
        if (!selectedFactorId) {
            return;
        }

        return availableFactors.find(x => x.id === selectedFactorId);
    };

    const getFactorDisplayName = (factor) => {
        if (factor.provider === 'GOOGLE') {
            return 'Google Authenticator';
        }

        if (factor.provider === 'OKTA' && factor.factorType === 'push') {
            return 'Okta - Send Notification';
        }

        if (factor.provider === 'OKTA' && factor.factorType === 'token:software:totp') {
            return 'Okta - Enter Code';
        }

        return 'unknown';
    };

    const isOktaProvider = (factor) => {
        if (factor.provider === 'OKTA' && factor.factorType === 'token:software:totp') {
            return true;
        }

        return false
    }

    const selectFactor = (factorId) => {
        setSelectedFactorId(factorId);
    };

    const goBackToFactors = () => {
        setSelectedFactorId(''); // clearing out the selected factor to show the list
        setValidationCode(''); // Clear the code when switching factors
    };

    const handleValidationCodeChanged = (e) => {
        setValidationCode(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            validateToken();
        }
    };

    const FactorOptionList = () => {
        return (
            <div className="factor-options" data-testid="factor-options">
                <h3>Choose your verification method:</h3>

                { getAvailableFactors().map(factor => (
                    <div key={factor.id} className={`factor ${factor.provider.toLowerCase()}`}>
                        <Button
                            variant="contained"
                            disableElevation
                            size="medium"
                            color="primary"
                            onClick={() => selectFactor(factor.id)}
                        >
                            {getFactorDisplayName(factor)}
                        </Button>
                        
                    </div>
                ))}
            </div>
        )
    };

    const validateToken = () => {
        const selectedFactor = availableFactors.find(x => x.id === selectedFactorId);
        if (!selectedFactor) {
            setErrorMessage('There was an error verifying your MFA. Please try logging in again.');
            return;
        }

        setIsProcessing(true);

        selectedFactor.verify().then(factorVerify => {
            factorVerify.verify().then(challengeResponse => {
                challengeResponse.verify({
                    passCode: validationCode
                }).then(challengeVerifyResponse => {
                    // console.log('challengeVerifyResponse', challengeVerifyResponse);
                    setIsProcessing(false);
                    // Notify the parent that MFA has succeeded and we can continue auth
                    onVerified({
                        sessionToken: challengeVerifyResponse.sessionToken,
                        expiresAt: challengeVerifyResponse.expiresAt,
                    })
                }).catch(err => {
                    // console.error('challengeVerify error', err);
                    setIsProcessing(false);

                    if (err.erroCode === 'E0000068') { // Invalid Passcode/Answer
                        setErrorMessage('The code you entered is not valid. Please try again.');
                    } else {
                        setErrorMessage('There was an error verifying your code. Please try again');
                    }
                });
            }).catch(err => {
                // console.error('challenge error', err);

                setIsProcessing(false);
                setErrorMessage('There was an error verifying your code. Please try again');
            });
        }).catch(err => {
            // console.error('verify error', err);
            
            setIsProcessing(false);
            setErrorMessage('There was an error verifying your code. Please try again');
        });
    };

    return (
        <div className="mfa-verify">
            {errorMessage ? (<p className="auth-error">{errorMessage}</p>) : null}

            { getAvailableFactors().length > 0
                ? !selectedFactorId
                    ? <FactorOptionList /> 
                    : <div className="verify-factor">                    
                        <h3>{getFactorDisplayName(getSelectedFactor())}</h3>
                        
                        {isOktaProvider(getSelectedFactor()) ?
                            <p className="tenant-indicator">{process.env.REACT_APP_OKTA_DOMAIN}</p>
                        : 
                            ""
                        }
                        
                        <TextField
                            id="txt-verify-token"
                            name="verifyToken"
                            label="Enter Code"
                            type="text"
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            value={validationCode}
                            onKeyPress={handleKeyPress}
                            onChange={handleValidationCodeChanged}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <IconVpnKey />
                                </InputAdornment>
                                ),
                            }}
                        />

                        <div className="verify-button-container">
                            <Button
                                variant="outlined"
                                disableElevation
                                size="medium"
                                className="btn-back"
                                data-testid="btn-back"
                                onClick={goBackToFactors}
                            >
                                Go Back
                            </Button>
                            
                            <Button
                                variant="contained"
                                disableElevation
                                size="medium"
                                color="primary"
                                className="btn-verify"
                                disabled={isProcessing}
                                onClick={validateToken}
                                >
                                Sign In
                            </Button>
                        </div>
                    </div>
                : <div className="add-factors" data-testid="add-factors">    
                    Your account requires MFA to log in, but no verification methods are currently configured. Please go to <a href="https://onewp.okta.com">https://onewp.okta.com</a> to set up MFA and then try logging in again.
                </div> 
            }
        </div>
    )
}

MfaVerify.propTypes = {
    availableFactors: PropTypes.array.isRequired,
    onVerified: PropTypes.func.isRequired,
};

export default MfaVerify;