import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';

import IconEmail from '@material-ui/icons/Email';
import IconMobilePhone from '@material-ui/icons/PhoneAndroid';
import IconOfficePhone from '@material-ui/icons/Phone';
import IconWork from '@material-ui/icons/Work';
import IconSupervisor from '@material-ui/icons/SupervisorAccount';
import IconLocation from '@material-ui/icons/LocationOn';
import Diversity3Icon from '@material-ui/icons/EmojiPeople';
import IconBiography from '@material-ui/icons/AssignmentInd';
import IconAccessTime from '@material-ui/icons/AccessTime'
import IconChat from '@material-ui/icons/Chat';
import IconFace from '@material-ui/icons/Face';
import IconLabel from '@material-ui/icons/Label';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/tabs/TabPanel';
import { useSnackbar } from 'notistack';
import { useError } from '../../hooks/useError';
import { formatPhoneNumber, formatDate, formatName, formatJobFamily } from '../../core/utils';

import './style.scss';

import { loadEmployeeView } from '../../core/employeeProfileHelper';
import { createResponsesObject, getGroupsWithQuestionResponses, getTopAweProfileGroups, loadAweProfile } from '../../core/aweProfileHelpers';
import ProfilePhoto from '../../components/profilePhoto/ProfilePhoto';
import ProfileContextNav from '../../components/employee/ProfileContextNav';
import SurveyForm from '../../components/workplaceExperience/SurveyForm';
import SeatingMap from '../../components/seatingMap/SeatingMap';
import ManagedEmployees from '../../components/employee/ManagedEmployees';
import ErrorDisplay from '../../components/error/Error';

import { timeZoneDifferential } from './TimeZoneCalc';

const ViewProfile = () => {
    const { employeeId } = useParams();
    
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [employee, setEmployee] = useState(null);    
    const [managedEmployees, setManagedEmployees] = useState([]);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [hasMap, setHasMap] = useState(false);
    const [errorObj, setError] = useError();
    
    const [workplaceSurveyTopLevelGroups, setWorkplaceSurveyTopLevelGroups] = useState([]);
    const [workplaceSurveyGroups, setWorkplaceSurveyGroups] = useState([]);
    const [workplaceSurveyQuestions, setWorkplaceSurveyQuestions] = useState([]);
    const [workplaceSurveyResponses, setWorkplaceSurveyResponses] = useState({});

    useEffect(() => {
        setCurrentTabIndex(0); // Reset the current tab to the first when changing employees
        setIsLoading(true);

        loadEmployeeView(employeeId).then((result) => {
            setEmployee(result.employee);
            setManagedEmployees(result.managedEmps);
            setHasMap(result.hasMap);
            setIsLoading(false);
        }).catch(e => {
            setError(true, e);
            enqueueSnackbar('There was an error loading the Employee info.', { 'variant': 'error' });
            setIsLoading(false);
        });

        loadAweProfile(employeeId).then((result) => {
            setWorkplaceSurveyGroups(result.groups);
            setWorkplaceSurveyQuestions(result.questions);

            const responseObj = createResponsesObject(result.responses);
            setWorkplaceSurveyResponses(responseObj);
    
            // Need the top level groups for the side context nav
            let topLevelGroups = getTopAweProfileGroups(result.groups);

            // only want to show sections that have questions answered
            let topLevelGroupsWithResponses = getGroupsWithQuestionResponses(topLevelGroups, result.questions, result.responses);
            setWorkplaceSurveyTopLevelGroups(topLevelGroupsWithResponses);
        }).catch(_ => {
            enqueueSnackbar('There was an error loading the Workplace profile.', { 'variant': 'error' });
        });
    }, [employeeId]);

    const handleTabChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    return (
        <section className="card">
            { errorObj.error ?
                <ErrorDisplay code={errorObj.code} message={errorObj.message} />
            :
            <article>
                {/* Loading Mask */}
                <Backdrop className="backdrop" open={isLoading}>
                    <CircularProgress />
                </Backdrop>

                {isLoading || !employee ? 
                    null
                    :
                    <div className="profileColumns">
                        <div className="profileCol">
                            <div className="photoWrapper">
                                <div className="profilePhoto">
                                    <ProfilePhoto employee={employee} />
                                </div>
                            </div>
                            
                            <h2>{formatName(employee)}</h2>
                            <p className="title">{employee.title}</p>
                            <br/>
                            <p className="department">{employee.department}</p>
                            <p className="company">{employee.agencyDisplayName}</p>

                            <ProfileContextNav groups={workplaceSurveyTopLevelGroups} />
                        </div>

                        <div className="profileCol">                            
                            <AppBar position="static" color="default" style={{marginBottom: 20}}>
                                <Tabs
                                    value={currentTabIndex}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="employee profile tabs"
                                >
                                    <Tab label="Details" {...a11yProps(0)} />
                                    {managedEmployees && managedEmployees.length > 0 ? <Tab label="Managing" {...a11yProps(2)} /> : null}

                                </Tabs>
                            </AppBar>
                            <TabPanel value={currentTabIndex} index={0} className="tab-content">
                                <div className="details-container">
                                    <div className="details-column">
                                        <a id="section-contact-info" />                            
                                        <h3 style={{margin: '0 0 20px 0'}}><IconLabel className="group-icon" /> Contact Info</h3>
                                        {
                                            employee.emailAddress 
                                            ? (
                                                <p>
                                                    <em><IconEmail /></em>{' '}
                                                    <a href={`mailto:${employee.emailAddress}`}>{employee.emailAddress}</a>
                                                </p>
                                            )
                                            : null 
                                        }

                                        {
                                            employee.mobilePhone 
                                            ? (
                                                <p>
                                                    <em><IconMobilePhone /></em>{' '}
                                                    <span className="meta-label">Mobile:</span>{' '}
                                                    <a href={`tel:${employee.mobilePhone}`}>{formatPhoneNumber(employee.mobilePhone)}</a>
                                                </p>
                                            )
                                            : null 
                                        }

                                        {
                                            employee.officePhone 
                                            ? (
                                                <p>
                                                    <em><IconOfficePhone /></em>{' '}
                                                    <span className="meta-label">Desk:</span>{' '}                                    
                                                    <a href={`tel:${employee.officePhone}`}>{formatPhoneNumber(employee.officePhone)}</a>
                                                </p>
                                            )
                                            : null 
                                        }
                                        
                                        {
                                            employee.emailAddress 
                                            ? (
                                                <p>
                                                    <em><IconChat /></em>{' '}
                                                    <a href={`https://teams.microsoft.com/l/chat/0/0?users=${employee.emailAddress}`} target="_blank" rel="noopener noreferrer" >Teams Chat</a>
                                                </p>
                                            )
                                            : null 
                                        }

                                        <a id="section-about" />
                                        <h3 style={{margin: '60px 0 20px 0'}}><IconLabel className="group-icon" /> About {employee.preferredName}</h3>

                                        <p>
                                            <em><IconSupervisor /></em>{' '}
                                            <span className="meta-label">Manager:</span>{' '}
                                            <Link to={`/employee/${employee.supervisorEmployeeId}`}>
                                                {employee.supervisorName}
                                            </Link>
                                        </p>
                                        <p>
                                            <em><IconWork /></em>{' '}
                                            <span className="meta-label">Hire Date:</span>{' '}
                                            {formatDate(employee.hireDate)}
                                        </p>
                                        <p>
                                            <em><Diversity3Icon /></em>{' '}
                                            <span className="meta-label">Job Family:</span>{' '}
                                            {formatJobFamily(employee.agencyJobName)}
                                        </p>
                                        {
                                            employee.preferredPronoun
                                            ? (
                                                <p>
                                                    <em><IconFace /></em>{' '}
                                                    <span className="meta-label">Pronouns:</span>{' '}
                                                    {employee.preferredPronoun}
                                                </p>
                                            )
                                            : null
                                        }                                


                                        <p className="bio-wrapper">
                                            <em><IconBiography /></em>{' '}
                                            <span className="meta-label">Biography:</span><br />
                                            <span className="text">{employee.bio ? employee.bio : <em>No bio available.</em>}</span>
                                        </p>
                                        
                                        <div className="awe-profile-responses">
                                            {workplaceSurveyGroups.length !== 0 && workplaceSurveyQuestions !== 0 && Object.keys(workplaceSurveyResponses).length !== 0 ?
                                            <SurveyForm
                                                workplaceSurveyGroups={workplaceSurveyGroups}
                                                workplaceSurveyQuestions={workplaceSurveyQuestions}
                                                workplaceSurveyResponses={workplaceSurveyResponses}
                                                workplaceSurveyTopLevelGroups={workplaceSurveyTopLevelGroups}
                                                readOnly={true}
                                                shareLocationDetails={employee.shareLocationDetails}
                                            />
                                            : 
                                            <div></div>
                                            }

                                        </div>
                                    </div>
                                    <div className="extra-details-column">
                                        <h3 style={{margin: '0 0 20px 0'}}><IconLabel className="group-icon" /> Time Zone:</h3>
                                        <p>
                                            <em><IconAccessTime /></em>
                                            {timeZoneDifferential(workplaceSurveyQuestions, workplaceSurveyResponses)}
                                        </p>
                                    </div>
                                </div>

                            </TabPanel>
                            { managedEmployees && managedEmployees.length > 0 ?
                                <TabPanel value={currentTabIndex} index={1} className="tab-content">
                                    <ManagedEmployees managedEmps={managedEmployees} />
                                </TabPanel>
                                : null
                            }
                        </div>
                    </div>
                }

            </article>
        }
        </section>
    )
};

export default ViewProfile;