import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProIcon from '@material-ui/icons/Work';
import PersonalIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import './style.scss';

export default function Topic(props) {
  const { questions, topicId, saveTopic, categoryResponses, handlePanelChange, topicName } = props;
  const [responses, setResponses] = useState(categoryResponses); // local responses state, initialized with what is passed in from the category component

  //TODO: determine a better way to do this. this seems really convoluted
  // i originally intended to have separate handlers for each field type thinking they would be fairly different, but there's lots of duplicate code between them
  const handleCheckboxChange = (e) => {
    // get the values
    var id = (e.target.id && !isNaN(e.target.id)) ? parseInt(e.target.id) : null; // checkbox id holds the response id (or a non-numeric value, which tells us there is no matching response for this question)
    var questionId = e.target.name && parseInt(e.target.name); // checkbox name holds the question id
    var responseValue = e.target.checked ? "True" : "False"; // checked is the checked/unchecked value selected

    var newResponses = [];
    const index = responses.findIndex(r => r.topicId === topicId && r.questionId === questionId);

    if (index !== -1) {
      // update the value in the responses
      newResponses = responses.map(r => (r.topicId === topicId && r.questionId === questionId ? { ...r, responseValue } : r));
    }
    else {
      // create a new response
      var newResponse = {
        "id": id ? id : null, // if we pass in null, a new response will be created. otherwise it will be updated
        // Employee ID is set on backend
        "topicId": topicId,
        "questionId": questionId,
        "responseValue": responseValue
      };
      // add the new response
      newResponses = responses.concat(newResponse);
    }
    setResponses(newResponses);
  };

  const handleTextFieldChange = (e) => {
    // get the values
    var id = (e.target.id && !isNaN(e.target.id)) ? parseInt(e.target.id) : null; // textfield id holds the response id (or a non-numeric value)
    var questionId = e.target.name && parseInt(e.target.name); // textfield name holds the question id
    var responseValue = e.target.value; // value from the text field

    var newResponses = [];
    const index = responses.findIndex(r => r.topicId === topicId && r.questionId === questionId);

    if (index !== -1) {
      // update the value in the responses
      newResponses = responses.map(r => (r.topicId === topicId && r.questionId === questionId ? { ...r, responseValue } : r));
    }
    else {
      // create a new response
      var newResponse = {
        "id": !isNaN(id) ? id : null, // if we pass in null, a new response will be created. otherwise it will be updated
        // Employee ID is set on backend
        "topicId": topicId,
        "questionId": questionId,
        "responseValue": responseValue
      };
      // add the new response
      newResponses = responses.concat(newResponse);
    }
    setResponses(newResponses);
  };

  // used to assign IDs to the fields
  const getCurrentResponseId = (currentTopicId, currentQuestionId) => {
    var response = (responses.find(r => r.topicId === currentTopicId && r.questionId === currentQuestionId));
    // if we have no response that matches the question, then we should return a unique value
    return response?.id ? response.id : currentTopicId + '-' + currentQuestionId;
  };

  // used to check/uncheck the boxes and populate the text field
  const getCurrentTextResponseValue = (currentTopicId, currentQuestionId) => {
    var response = (responses.find(r => r.topicId === currentTopicId && r.questionId === currentQuestionId));
    return response ? response.responseValue : '';
  };

  // check the responses array for matches. used to check the checkboxes when mounting
  // in javascript, find returns the first matching element found or undefined if not
  const isChecked = (currentTopicId, currentQuestionId) => {
    return (responses.find(r => r.topicId === currentTopicId && r.questionId === currentQuestionId && r.responseValue === "True")) ? true : false;
  };

  return (
    <article className="topic-container">
      <FormGroup>

        {/* Personal Questions */}
        <div className="grouprow">
          <div className="personal">
            <span className="colorbar"></span>
            <h5><PersonalIcon className="icon" /> Personal</h5>

            <ul className="topicQuestions">

              {/* personal checkboxes */}
              {props && questions &&
                questions.filter(q => q.expertiseType === 'Personal' && q.questionType === 'Boolean')
                  .map((question, index) => (
                    <li key={`${topicId + question.id}`}>
                      <FormControlLabel label={question.questionText}
                        control={<Checkbox
                          name={`${question.id}`}
                          id={`${getCurrentResponseId(topicId, question.id)}`}
                          className="personalCheck"
                          checked={isChecked(topicId, question.id)} />}
                          onChange={handleCheckboxChange}
                      />
                    </li>
                  ))}

              {/* personal free text */}
              {props && questions &&
                questions.filter(q => q.expertiseType === 'Personal' && q.questionType === 'Text')
                  .map((question, index) => (
                    <li key={`${topicId + question.id}`}>
                      {<TextField style={{ marginTop: 20, marginBottom: 10 }}
                        id={`${getCurrentResponseId(topicId, question.id)}`}
                        fullWidth
                        variant="outlined"
                        name={`${question.id}`}
                        label={question.questionText}
                        value={getCurrentTextResponseValue(topicId, question.id)}
                        onChange={handleTextFieldChange}
                      />}
                    </li>
                  ))}
            </ul>
          </div>

          {/* Professional Questions */}
          <div className="professional">
            <span className="colorbar"></span>
            <h5><ProIcon className="icon" /> Professional</h5>
            <ul className="topicQuestions">

              {/* professional checkboxes */}
              {props && questions &&
                questions.filter(q => q.expertiseType === 'Professional' && q.questionType === 'Boolean')
                  .map((question, index) => (
                    <li key={`${topicId + question.id}`}>
                      <FormControlLabel label={question.questionText} 
                        control={<Checkbox
                          name={`${question.id}`}
                          id={`${getCurrentResponseId(topicId, question.id)}`}
                          className="professionalCheck"
                          checked={isChecked(topicId, question.id)} />}
                          onChange={handleCheckboxChange}
                      />
                    </li>
                  ))}

              {/* professional free text */}
              {props && questions &&
                questions.filter(q => q.expertiseType === 'Professional' && q.questionType === 'Text')
                  .map((question, index) => (
                    <li key={`${topicId + question.id}`}>
                      {<TextField style={{ marginTop: 20, marginBottom: 10 }}
                        id={`${getCurrentResponseId(topicId, question.id)}`}
                        fullWidth
                        variant="outlined"
                        name={`${question.id}`}
                        label={question.questionText}
                        value={getCurrentTextResponseValue(topicId, question.id)}
                        onChange={handleTextFieldChange}
                      />}
                    </li>
                  ))}
            </ul>

          </div>
          <div className="save-button-container">
            <Button variant="contained" color="primary" id={`${topicId}`} onClick={() => saveTopic(responses, topicId, topicName)}><SaveIcon className="save-icon" /> Save</Button>
            <Button variant="contained" onClick={handlePanelChange(`panel${topicId}`)}>Cancel</Button>
          </div>
        </div>

      </FormGroup>
    </article>
  );

}