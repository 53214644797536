import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ProfileQuestionService from '../../core/services/ProfileQuestionService';

import './calloutBanner.scss';

const CalloutBanner = () => {
    // The AWE profile questions are group ID 2
    const questionGroupId = 2;

    const hideBannerCookieName = 'awe-hide-banner';
    const [shouldDisplayBanner, setShouldDisplayBanner] = useState(false);
    const [cookies, setCookie] = useCookies([hideBannerCookieName]);
    
    useEffect(() => {
        // If the user has the cookie to hide the banner set, don't do the check at all
        const hasHideBannerCookie = cookies[hideBannerCookieName] === 'true';
        if (hasHideBannerCookie) {
            setShouldDisplayBanner(false);
        } else {
            // Display the banner if the user doesn't have any responses filled out
            // VAN-156: Display the banner if the updated questions haven't been filled out
            ProfileQuestionService.getResponseSummary(questionGroupId).then(response => {
                const hasAnyResponses = response.responses.filter(x => x.hasResponse === true).length > 0;

                // Not a great way to handle this, may want to start tracking questions as enums? 
                const newQuestions = [
                    "Quick Question",
                    "Question or request that requires research/follow-up",
                    "Brainstorm/flesh out an idea",
                    "After hours/out of office urgent request"
                ];

                const missingNewQuestions = response.responses.filter(x => newQuestions.includes(x.questionText) && !x.hasResponse).length > 0;
                setShouldDisplayBanner(!hasAnyResponses || missingNewQuestions);
            });
        }
    }, []);

    const onHideClicked = () => {
        setShouldDisplayBanner(false);

        // Set to expire in 24 hours
        const expire = new Date(new Date().setHours(new Date().getHours() + 24));
        setCookie(hideBannerCookieName, 'true', { expires: expire });
    }

    return (
        shouldDisplayBanner
            ? 
            <div className="aweCalloutBanner">
                <span className="text">                   
                    Please take a minute to{' '}
                    <Link to="/profile">complete your profile</Link>.
                </span>
                <IconButton
                    key="close"
                    title="close"
                    color="inherit"
                    className="closeButton"
                    onClick={onHideClicked}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            : null
    );
}

export default CalloutBanner;