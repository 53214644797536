import React from 'react';
import PropTypes from 'prop-types';

// Source: https://medium.com/the-coders-guide-to-javascript/smooth-scrolling-anchor-menu-in-reactjs-175030d0bce2

/**
 * Renders a link to scroll to another part of the page by its element ID
 * @param {*} props 
 * @returns 
 */
export default function AnchorScrollLink(props) {
    const { elementId, linkText } = props;

    const handleClick = (event) => {
        event.preventDefault();

        const target = document.getElementById(elementId);
        if (target) {
            target.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <a
            href={`#${elementId}`}
            onClick={handleClick}
            aria-label={`Scroll to ${elementId}`}
        >
            {linkText}
        </a>
    )
};

AnchorScrollLink.propTypes = {
    elementId: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
};