import React, { useState, useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import IconClose from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSnackbar } from 'notistack';

import EmployeeService from '../../core/services/EmployeeService';

import './ProfilePhotoEditor.scss';

// Source: https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/App.js

// Setting a higher ratio avoids blurriness in the crop preview
const pixelRatio = 4;

function ProfilePhotoEditor({
    handleClose
}) {
    const { enqueueSnackbar } = useSnackbar();

    // These all deal with the image cropping
    const [uploadImage, setUploadImage] = useState();
    const imageRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({unit: 'px', width: 200, aspect: 1 })
    const [completedCrop, setCompletedCrop] = useState(null);
    
    // These are for updating the UI (errors, etc)
    const [isFileSelected, setIsFileSelected] = useState(false);
    // used to prevent uploading before the crop/preview is loaded
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [croppedImageData, setCroppedImageData] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [imageError, setImageError] = useState();

    const handleFileSelected = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                setImageError('Your profile photo must be a JPG or PNG file');
                return;
            }

            setIsPreviewLoading(true);
            setIsFileSelected(true);

            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => setUploadImage(fileReader.result));
            fileReader.readAsDataURL(file)
        }
    };

    const onLoad = useCallback(img => {
        imageRef.current = img;
        setIsPreviewLoading(false);
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imageRef.current) {
            return;
        }

        const image = imageRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const context = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        context.imageSmoothingEnabled = false;

        context.drawImage(
            image, 
            crop.x * scaleX, 
            crop.y * scaleY, 
            crop.width * scaleX, 
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height);

        // Convert the canvas to a data: URI
        let imageData = canvas.toDataURL('image/png');
        // The server doesn't need this 'prefix' stuff, so removing it
        imageData = imageData.replace('data:image/png;base64,', '');
        setCroppedImageData(imageData);
    }, [completedCrop]);

    const handleUploadImage = () => {
        setImageError(null); // Clear the error message before starting

        setIsUploading(true);

        EmployeeService.saveProfilePhoto(croppedImageData).then(_ => {
            setIsUploading(false);
            handleClose(true);
            
            enqueueSnackbar(`Profile photo uploaded.`, { 'variant': 'success' });
        }).catch(() => {
            setIsUploading(false);
            setImageError('There was an error uploading the image. Please try again.');
        });
    }

    return (
        <div className="modal">
            <IconClose className="close-modal" onClick={() => handleClose(false)} />
            <div className="modal-content change-profile-photo-content">                
                {/* Loading Mask */}
                <Backdrop className="backdrop" open={isUploading}>
                    <CircularProgress />
                </Backdrop>    

                <h2>Change Your Profile Photo</h2>
                
                {imageError ? (<p className="image-error">{imageError}</p>) : null }

                {!isFileSelected ? (
                    <div className="file-selector">
                        <input
                            type="file"
                            id="raised-button-file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleFileSelected}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" color="default" component="span">
                                Choose File...
                            </Button>
                        </label>
                    </div>
                ) : null }

                {isPreviewLoading ? (<p className="preview-loading-msg">Image preview is loading...</p>) : null}

                <ReactCrop
                    src={uploadImage}
                    onImageLoaded={onLoad}
                    crop={crop}
                    keepSelection={true}
                    style={{width: '100%' }}
                    onChange={c => setCrop(c)}
                    onComplete={c => setCompletedCrop(c)}
                />

                <canvas
                    ref={previewCanvasRef}
                    style={{
                        display: 'none',
                        width: completedCrop?.width ?? 0,
                        height: completedCrop?.height ?? 0
                    }}
                />
                
                <div className="actions">
                    <Button
                        variant="contained"
                        color="primary" 
                        disabled={!isFileSelected || isPreviewLoading || isUploading}
                        onClick={handleUploadImage}
                        >
                        { isUploading ? 'Uploading photo...' : 'Upload Photo' }
                    </Button>
                </div>
            </div>
        </div>
    )
}

ProfilePhotoEditor.propTypes = {
    handleClose: PropTypes.func
}

export default ProfilePhotoEditor;