/* eslint-disable react-hooks/exhaustive-deps */
// Specifically ignoring these because it's complaining about method deps

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import QuestionnaireService from '../../core/services/QuestionnaireService';
import Topic from '../../components/topic/Topic';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import IconArrowLeft from '@material-ui/icons/ArrowLeft';
import Button from '@material-ui/core/Button';

import { useSnackbar } from 'notistack';

import './style.scss';

const hiddenStyle = {
    display: 'none'
};

const handleBackButton = () => document.location = '/survey';

export default function Category(props) {
    const [category, setCategory] = useState({});
    const [questions, setQuestions] = useState([]);
    const [expanded, setExpanded] = useState(false);  // for the accordion
    const [responses, setResponses] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    let { catId } = useParams();  // /survey/category/catId

    // on load get the category, our responses, and the questions
    useEffect(() => {
        getCategoryResponsesForEmployee(catId);
        getCategory(catId);
        getQuestions();
    }, [catId]);


    const getCategoryResponsesForEmployee = (categoryId) => {
        QuestionnaireService.getCategoryResponsesForEmployee(categoryId)
            .then(response => {
                setResponses(response);
            })
            .catch(e => {
                console.error(e);
            });
    };

    // load up this specific category
    const getCategory = (catId) => {
        QuestionnaireService.getCategory(catId)
            .then(response => {
                setCategory(response);
            })
            .catch(e => {
                enqueueSnackbar(`Failed to retrieve categories`, { 'variant': 'error' });
                console.error(e);
            });
    };

    const saveResponses = (responses, topicId, topicName) => {
        QuestionnaireService.saveResponses(responses)
            .then(response => {
                setResponses(response);
                enqueueSnackbar(`${topicName} responses saved`, { 'variant': 'success' });
                // close the accordion
                setExpanded(false);
            })
            .catch(e => {
                enqueueSnackbar(`Failed to save ${topicName} responses`, { 'variant': 'error' });
                console.error(e);
            });
    }

    // load the questions into state
    // try the global window variable first-- if it's not there, reach out to the server and put it there afterwards
    const getQuestions = () => {
        if (window.vantageQuestions) {
            setQuestions(window.vantageQuestions)
        }
        else {
            QuestionnaireService.getAllQuestions()
                .then(response => {
                    // tack the question set onto the window so we can get it there wherever we need it
                    window.vantageQuestions = response;
                    setQuestions(response);
                })
                .catch(e => {
                    enqueueSnackbar(`Failed to retrieve Questions`, { 'variant': 'error' });
                    console.error(e);
                });
        }
    };

    // Accordions
    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <section>
                <article>
                    <h1>Survey: Topics</h1>
                </article>
            </section>

            <section className="card gray">
                <article>
                    <div style={catId ? null : hiddenStyle} id={catId} className="category-container">
                        {category.name && <h2>{`Topics for ${category.name}`}</h2>}
                        <p>For each of the topics below, select those in which you have expertise and share what level of expertise you have by clicking each statement that meets your expertise level (professional and/or personal). When you complete a particular topic’s statement list, make sure to click the Save button to save your progress.</p>
                        <p>You can return at any time to update your results.</p>
                        {category.topics ?
                            category.topics.map((topic, index) =>
                            ( // unmountOnExit greatly improves the performance by not rendering the panels that aren't expanded. BUT when we exit a panel, we will need to persist the question responses or else they're lost
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === `panel${topic.id}`} onChange={handlePanelChange(`panel${topic.id}`)} key={topic.id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                                        <h2 className="heading-primary">{topic.name} {(responses.findIndex(x => x.topicId === topic.id) >= 0) ? <CheckIcon className="category-complete-icon" titleAccess="You have responded to questions in this topic" /> : null}</h2>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Topic topicName={topic.displayName}
                                            key={`${topic.id}`}
                                            categoryId={category.id}
                                            topicId={topic.id}
                                            questions={questions}
                                            saveTopic={saveResponses}
                                            categoryResponses={responses}
                                            handlePanelChange={handlePanelChange} />
                                    </AccordionDetails>
                                </Accordion>

                            ))
                            : <section className="card"><article>Loading...</article></section>
                        }
                        <Button className="button-back" variant="outlined" onClick={handleBackButton}><IconArrowLeft /> Back to Categories</Button>
                    </div>
                </article>
            </section>
        </>
    );
}