import React from 'react';
import config from '../config';

function GlobalFooter(props) {

  const { version } = props;
  return (
    <div id="global-footer">
        <p>
            <a href={`https://omceg.freshservice.com/support/catalog/items`} target={`_blank`}>Need Help?</a>
            <span className="s-versionText-537">{config.site.name} v{version}</span>
            <span>&copy; OEG</span>
        </p>
    </div>
  );
}

export default GlobalFooter;