import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useError } from '../../hooks/useError';

import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import ProfileQuestionService from '../../core/services/ProfileQuestionService';
import Question from '../../components/introQuestions/Question';
import ErrorDisplay from '../../components/error/Error';

import './style.scss';

export default function IntroQuestions(props) {
    const { groupId } = useParams();

    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [responses, setResponses] = useState([]);

    const [errorObj, setError] = useError();

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getQuestions(groupId), getResponses(groupId)])
            .then(() => {
                setIsLoading(false);
            }).catch(e => {
                setError(true, e);
            });
    }, [groupId])

    const getQuestions = async (groupId) => {
        const questions = await ProfileQuestionService.getQuestions(groupId);
        setQuestions(questions);
    };

    const getResponses = async (groupId) => {
        const responses = await ProfileQuestionService.getResponses(groupId);
        setResponses(responses);
    };

    const getQuestionResponse = (questionId) => {
        const response = responses.find(x => x.questionId === questionId);
        return response && response.responseValue
            ? response.responseValue
            : '';
    };

    const handleResponseChanged = (questionId, value) => {
        let allResponses = [...responses];

        let existingResponse = allResponses.find(x => x.questionId === questionId);
        if (existingResponse) {
            existingResponse = {
                ...existingResponse,
                questionId,
                responseValue: value
            };
            const otherResponses = allResponses.filter(x => x.questionId !== questionId);
            allResponses = [...otherResponses, existingResponse];
        }
        else {
            const newResponse = {
                questionId,
                responseValue: value
            };
            allResponses = [...responses, newResponse];
        }

        setResponses(allResponses);
    };

    const validateQuestions = () => {
        const questionIds = questions.map(x => x.id);
        const respondedQuestionIds = responses.map(x => x.questionId);

        const missingIds = questionIds.filter(x => !respondedQuestionIds.includes(x));
        return missingIds;
    };

    const getQuestionValidationResult = (questionId) => {
        if (validateQuestions().includes(questionId)) {
            return 'missing'
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (validateQuestions().length > 0) {
            return;
        }

        setIsLoading(true);

        ProfileQuestionService.updateResponses(groupId, responses).then(() => {
            setIsLoading(false);
            enqueueSnackbar('Responses saved', { 'variant': 'success' });
            history.push('/survey');
        }).catch(() => {
            enqueueSnackbar('Error saving responses', { 'variant': 'danger' });
        });
    };

    return (
        <>
            { errorObj.error ?
                <ErrorDisplay code={errorObj.code} message={errorObj.message} />
            :
            <section className="card narrow">
                {/* Loading Mask */}
                <Backdrop className="backdrop" open={isLoading}>
                    <CircularProgress />
                </Backdrop>

                
                <article className="questionsHeader">
                    <h1>Survey: Introduction Questions</h1>
                    <p>The goal of this survey is to update our expert pool across a broad range of topics. We are specifically looking at two different levels of expertise:</p>
                    <ul>
                        <li><strong>Professional</strong> &ndash; a comprehensive knowledge and/or skill in the topic based on your prior work experience (GMR or elsewhere)</li>
                        <li><strong>Personal</strong> &ndash; a comprehensive knowledge and/or skill in a topic based on your personal passion</li>
                    </ul>
                    <p>Everyone who takes this survey could be called upon for their expertise to better serve agency clients. For example, some experts have participated in creative brainstorms, organized focus groups or written white papers on a topic.</p>
                    <hr className="separator" />
                    <p>To start, please answer each question below to get a better understanding of you level of comfort to participate in this Vantage process.</p>
                    <p>You can return at any time to update your results.</p>
                </article>

                <article>
                    <h3>How comfortable are you with the following:</h3>
                    <form onSubmit={handleFormSubmit}>
                        {questions.map(question => (
                            <Question
                                key={question.id}
                                question={question}
                                responseValue={getQuestionResponse(question.id)}
                                validationError={getQuestionValidationResult(question.id)}
                                onResponseChanged={(questionId, value) => handleResponseChanged(questionId, value)}
                            />
                        ))}
                        {
                            validateQuestions().length > 0
                                ? (<p className="error">To proceed, please answer all questions above.</p>)
                                : null
                        }
                        <hr className="separator" />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={validateQuestions().length > 0}>
                            <SaveIcon className="save-icon" />
                            Save & Continue
                        </Button>
                    </form>
                </article>   
            </section>
            }
        </>
    );
}
