import React, { useState, useEffect, useCallback } from 'react';

import { NavLink } from 'react-router-dom';

import ProfilePhoto from '../../components/profilePhoto/ProfilePhoto';

import EmployeeService from '../../core/services/EmployeeService';

import CalloutBanner from '../../components/workplaceExperience/CalloutBanner';

import { useError } from '../../hooks/useError';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconSearch from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import { Card, CardContent, CardActionArea, Typography, CardActions } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import BarChartIcon from '@material-ui/icons/BarChart';
import ErrorDisplay from '../../components/error/Error';

import './style.scss';

export default function Home(props) {

    const [employee, setEmployee] = useState(null); // used by <ProfilePhoto> to retrieve the emp's profile picture
    const [empSearch, setEmpSearch] = useState('');  // text entered in the Employee Name search box
    const [errorObj, setError] = useError();

    const handleEmpSearchChange = e => {
        setEmpSearch(e.target.value);
    };

    const handleEmployeeSearchClicked = () => {
        if (!empSearch || empSearch?.trim() === '') return;
        document.location = `/directory?name=${empSearch.trim()}`;
    };

    // let users hit the enter key to search
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            handleEmployeeSearchClicked();
        }
    };

    useEffect(() => {
        // we need the emp for the profile photo
        EmployeeService.getCurrentEmployee().then(emp => {
            setEmployee(emp);
        }).catch(e => {
            setError(true, e, "Sorry, we couldn't load your account. Are you connected and logged in?");
        });
    }, []);


    return (
        <div>
            <CalloutBanner />
            { errorObj.error ?
                <ErrorDisplay code={errorObj.code} message={errorObj.message} detail={errorObj.detail} />
            :
            <div className='home-container'>
                <div className='home-content'>
                    <div className='home-welcome'>Welcome to Vantage</div>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Card className='card home-search'>
                                <CardContent>
                                    <Typography
                                        className="MuiTypography--heading"
                                        variant="h5"
                                        align="left"
                                        sx={{ paddingTop: '50px' }}
                                        gutterBottom={true}
                                    >
                                        Search for a StoryMaker
                                    </Typography>
                                    <div className="home-search-bar">
                                        <FormControl className="searchFormControl search-input">
                                            <FormHelperText id="outlined-weight-helper-text">StoryMaker Name</FormHelperText>
                                            <TextField
                                                onChange={handleEmpSearchChange}
                                                onKeyPress={handleKeyPress}
                                                value={empSearch}
                                                id="userSearch"
                                                variant="outlined"
                                                label=""
                                                helperText=""
                                                disabled={false}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconSearch />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                        <Button className="search-button" variant="contained" color="primary" onClick={handleEmployeeSearchClicked}> SEARCH</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className='card'>
                                <CardActionArea component={NavLink} to="/profile" className="card-action">
                                    <CardContent>
                                        <div className="home-profile-content">
                                            <div className="profile-text">
                                                <Typography
                                                    className="MuiTypography--heading"
                                                    variant="h5"
                                                    align="left"
                                                    gutterBottom={true}
                                                >
                                                    Your StoryMaker Profile
                                                </Typography>
                                                <Typography
                                                    className="MuiTypography--subheading"
                                                    variant="subtitle1"
                                                    align="left"
                                                >
                                                    Create your Email Signature. View and update your photo, bio, and communication preferences.
                                                </Typography>
                                            </div>
                                            <div className="photoWrapper">
                                                <div className="profilePhoto">
                                                    <ProfilePhoto employee={employee} forceRefresh={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className='card'>
                                <CardActionArea component={NavLink} to="/seatingmaps" className="card-action">
                                    <CardContent>
                                        <div className="experience-hubs-content">
                                            <div className="profile-text">
                                            <Typography
                                            className="MuiTypography--heading"
                                            variant="h5"
                                            align="left"
                                            gutterBottom={true}
                                        >
                                            Experience Hubs
                                        </Typography>
                                        <Typography
                                            className="MuiTypography--subheading "
                                            variant="subtitle1"
                                            align="left"
                                        >
                                            View our helpful guides for visiting Experience Hubs.
                                        </Typography>
                                            </div>
                                            <div className="photoWrapper">
                                                <div className="profilePhoto">
                                                    <EventSeatIcon fontSize='inherit' className="icon-container icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className='card'>
                                <CardActionArea component={NavLink} to={{pathname: "https://app.powerbi.com/groups/8d071738-2468-48cd-a171-a2b8c3fc821d/reports/48819c15-54e2-4ab4-a3b8-1a590e5083be/ReportSection?experience=power-bi&clientSideAuth=0"}} target="_blank" className="card-action">
                                    <CardContent>
                                        <div className="home-storymakermap-content">
                                            <div className="storymakermap-text">
                                                <Typography
                                                    className="MuiTypography--heading"
                                                    variant="h5"
                                                    align="left"
                                                    gutterBottom={true}
                                                >
                                                    StoryMaker Map
                                                </Typography>
                                                <Typography
                                                    className="MuiTypography--subheading"
                                                    variant="subtitle1"
                                                    align="left"
                                                >
                                                    View a map of StoryMaker Locations.
                                                </Typography>
                                            </div>
                                            <img className="full-card-image" src="/assets/img/city.png" alt="Map" />
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
            }
        </div>

    );
}