import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { formatPhoneNumber, formatName } from '../../core/utils';
import ProfilePhoto from '../profilePhoto/ProfilePhoto';

import './style.scss';

export default function ManagedEmployees(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { managedEmps } = props;

    const history = useHistory();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleManagedEmployeeClick = (employee) => {
        history.push(`/employee/${employee.id}`);
    };
    return (
        <div>
            <TableContainer>
                <Table size="small" className="user-table">
                    <TableHead>
                        <TableRow key="header">
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Email</TableCell>
                        </TableRow>
                    </TableHead>
                    {/* we check employees to see if our base set of emps has loaded. but we map on the filteredEmployees */}
                    {managedEmps && managedEmps.length > 0 ?
                        <TableBody>
                            {
                                managedEmps
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((emp) => (
                                        <TableRow key={emp.id} onClick={() => handleManagedEmployeeClick(emp)} className="clickable">
                                            <TableCell>
                                                {/* If we are going to include images, we really need to paginate or limit the results that get returned. performance isn't great when many images are loaded and rendered */}
                                                <ProfilePhoto className="avatar-table" employee={emp} lazyLoad={true} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Link to={`/employee/${emp.id}`}><b>{formatName(emp)}</b></Link>
                                            </TableCell>
                                            <TableCell>{getPhoneNumber(emp)}</TableCell>
                                            <TableCell>{emp.title}</TableCell>
                                            <TableCell>{emp.emailAddress}</TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                        :
                        <TableBody><TableRow><TableCell><div>Loading...</div></TableCell></TableRow></TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={managedEmps.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

ManagedEmployees.propTypes = {
    managedEmps: PropTypes.array,
    handleManagedEmployeeClick: PropTypes.func
}

// VAN-155 Prefer to use the office phone for an employee but fall back to mobile phone
function getPhoneNumber(employee) {
    if(employee.officePhone)
        return formatPhoneNumber(employee.officePhone);
    return formatPhoneNumber(employee.mobilePhone);
}