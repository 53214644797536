import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Question from '../introQuestions/Question';
import IconLabel from '@material-ui/icons/Label';
import QuestionMarkIcon from '@material-ui/icons/Help';
import ProfileQuestionSubGroupIds from '../../core/ProfileQuestionSubGroupIds';
import config from '../../config';
import './style.scss';

const SurveyForm = (props) => {
    const { 
        onResponsesChanged,
        workplaceSurveyGroups,
        workplaceSurveyQuestions, 
        workplaceSurveyResponses,
        workplaceSurveyTopLevelGroups, // <ViewProfile> passes in groups with responses. <Profile> passes in all groups.
        readOnly,
        shareLocationDetails
    } = props;

    const [responses, setResponses] = useState({});

    useEffect(() => {
        if (Object.keys(workplaceSurveyResponses).length !== 0){
                setResponses(workplaceSurveyResponses);
        }

    }, [workplaceSurveyResponses]);

    const getQuestionsByGroup = (group) => {
        if (group.name === 'Communication Preferences') {
            return getCommunicationPrefenceSection(group);
        }

        if (group.name === 'DiSC Profile') {
            return getDiSCAssessmentSection(group);
        }

        let groupQuestions = getQuestionsByGroupId(group.id);
        
        return (
            groupQuestions.map(question => (
                <Question
                    key={`question-${question.id}`}
                    question={question}
                    responseValue={getQuestionResponse(question.id)}
                    isMultiline={question.questionText !== "City" && question.questionText !== "State"}
                    isDisabled={question.questionText === "City" || question.questionText === "State"}
                    onResponseChanged={(questionId, value) => handleResponseChanged(questionId, value)}
                    readOnly={readOnly}
                />
            ))
        )
    };

    const getQuestionsForGroup = (groupId) => {
        let groupQuestions = getQuestionsByGroupId(groupId);
        return (
            groupQuestions.map(question => (
                <Question
                    key={`question-${question.id}`}
                    question={question}
                    responseValue={getQuestionResponse(question.id)}
                    isMultiline={question.questionText !== "City" && question.questionText !== "State"}
                    isDisabled={question.questionText === "City" || question.questionText === "State"}
                    onResponseChanged={(questionId, value) => handleResponseChanged(questionId, value)}
                    readOnly={readOnly}
                />
            ))
        )
    };

    const getQuestionsByGroupId = (groupId) => {
        let groupQuestions = workplaceSurveyQuestions.filter(x => x.subGroupId === groupId);

        // The whereDoYouWork group is the only group that needs to be filtered - return all questions for all other groups
        if (groupId !== ProfileQuestionSubGroupIds.whereDoYouWork) {
            return groupQuestions;
        }

        // If someone is editing their own profile, no need to filter the whereDoYouWork group
        if (!readOnly) {
            return groupQuestions;
        }

        // Since readOnly = true, always hide the "Share Location with Colleagues?" question
        groupQuestions = groupQuestions.filter(x => x.questionText !== "Share Location with Colleagues?");

        // If the owner of the current profile has chosen to hide their location details, remove those questions as well
        if (!shareLocationDetails) {
            groupQuestions = groupQuestions.filter(x => x.questionText !== "City" && x.questionText !== "State" && x.questionText !== "Country");
        }

        return groupQuestions;
    };

    const getCommunicationPrefenceSection = (group) => {
        return (
            <>
                {
                    /* Don't show the intro text if in readonly (display) mode */
                    !readOnly
                        ? 
                            <p>
                                Please select the best communication method for the following scenarios:         
                            </p>
                        : null
                }
                {getQuestionsForGroup(group.id)}
            </>
        )
    };

    const getDiSCAssessmentSection = (group) => {
        return (
            <>
                {
                    /* Don't show the document link if in edit mode */
                    readOnly
                        ? 
                        <a className="disc-help" tabIndex="0" aria-disabled="false" href={config.discAssessment.discAssessmentInstructions} target="_blank">
                            <span className="disc-icon"><QuestionMarkIcon style={{ fontSize: 24 }}/></span>
                            <span className="info">How to Work Effectively</span>
                        </a>
                        : null
                }
                {getQuestionsForGroup(group.id)}
            </>
        )
    };
    
    const getQuestionResponse = (questionId) => {
        const response = responses[questionId];
        return response && response.responseValue
            ? response.responseValue
            : '';
    };
    
    const handleResponseChanged = (questionId, value) => {
        const allResponses = {...responses};

        const existingResponse = responses[questionId];
        if (existingResponse) {
            existingResponse.responseValue = value;
            allResponses[questionId] = existingResponse;   
        } else {
            allResponses[questionId] = {
                questionId: questionId,
                responseValue: value,
            };
        }

        setResponses(allResponses);
        onResponsesChanged(allResponses);
    };

    return (
        <div className={`survey-container ${readOnly ? 'profile-display': ''}`}>
            {workplaceSurveyTopLevelGroups.map(group => (
                <div key={`group-${group.id}`} id={`section-awe-group-${group.id}`} className={group.groupKey ? `question-group group-${group.groupKey}` : 'question-group'}>
                    <h3><IconLabel className="group-icon" /> {group.name}</h3>
                    { 
                        getQuestionsByGroup(group)
                    }
                </div>
            ))}
        </div>
    )
};

SurveyForm.propTypes = {
    onResponsesChanged: PropTypes.func,
    workplaceSurveyGroups: PropTypes.array.isRequired,
    workplaceSurveyQuestions: PropTypes.array.isRequired,
    workplaceSurveyResponses: PropTypes.object.isRequired,
    workplaceSurveyTopLevelGroups: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
    shareLocationDetails: PropTypes.bool
}

export default SurveyForm;