import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.scss';

export default function Error({ 
    code, 
    message, 
    detail,
}) {
    return (
        <section className="card error-page">
            { code === 404 ? <h1>{code}</h1> : null }
            <h2>That's not supposed to happen.</h2>            
            <div className="error_info">
                <img src="/assets/img/404.gif" alt="Not Found"/>

                {detail ? 
                    <p>{detail}</p>
                : 
                    <p>Sorry, something went wrong.</p>
                }
                
                <p>You can try again later, or head <Link to="/">back home</Link>.</p>
            </div>
            <div className="detailed_info">{ code > 0 ? code + " - " : null } {message}</div>
        </section>
    )
}

Error.propTypes = {
    code: PropTypes.number,
    message: PropTypes.string,
    detail: PropTypes.string
};