import React, { useState, useEffect } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

export default function MultipleChoiceResponse(props) {
    const { question, response, onChangeResponse } = props;
    const [responseValue, setResponseValue] = useState('');

    useEffect(() => {
        setResponseValue(response);
    }, [response]);

    const handleChange = (e) => {
        const fieldValue = e.target.value;
        setResponseValue(fieldValue);
        onChangeResponse(question.id, fieldValue)
    };

    return (
        <RadioGroup 
            value={responseValue}
            aria-label={question.questionText}
            onChange={handleChange}>
            {question.options.map(option => (
                <FormControlLabel
                    key={option.id}
                    value={option.optionValue}
                    label={option.optionText}
                    control={<Radio />}
                />
            ))}
        </RadioGroup>
    )
};
